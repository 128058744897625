import { MarkdownModule } from 'ngx-markdown';
import { LoginSignupComponent } from './../../shared/login-signup/login-signup.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { IndexComponent } from './pages/index/index.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'src/app/shared/shared.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SlotBookingComponent } from './pages/slot-booking/slot-booking.component';
import {
  MatStepperModule,
  MatCheckboxModule,
  MatRadioModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatButtonToggleModule,
  MatDialogModule,
  MatTableModule,
  MatButtonModule,
  MatSortModule
} from '@angular/material';
import { BookEventComponent } from './components/book-event/book-event.component';
import { AdsAdvertisementComponent } from './components/ads-advertisement/ads-advertisement.component';
import { ExampleHeader } from './components/custom-header/custom-header.component';
import { PaymentComponent } from './components/payment/payment.component';
import { HttpClientModule } from '@angular/common/http';
import { WaiverFormComponent } from './components/waiver-form/waiver-form.component';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { NgMarqueeModule } from 'ng-marquee';

@NgModule({
  declarations: [
    IndexComponent,
    ExampleHeader,
    SlotBookingComponent,
    BookEventComponent,
    AdsAdvertisementComponent,
    PaymentComponent,
    WaiverFormComponent,
    AboutComponent,
    ContactComponent,
    PaymentStatusComponent,
  ],
  imports: [
    NgMarqueeModule,
    CommonModule,
    HomeRoutingModule,
    CommonModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    SharedModule,
    SwiperModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatTableModule,
    HttpClientModule,
    MatSortModule,
    MarkdownModule.forChild(),
    NgxSpinnerModule
  ],
  entryComponents: [
    BookEventComponent,
    ExampleHeader,
    LoginSignupComponent,
    PaymentComponent,
    WaiverFormComponent,
    PaymentStatusComponent
  ],
  providers: [DatePipe, NgxSpinnerService],
  exports: [AdsAdvertisementComponent]
})
export class HomeModule { }
