import { EventsService } from './../../services/events.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';


@Component({
  selector: 'app-book-event',
  templateUrl: './book-event.component.html',
  styleUrls: ['./book-event.component.scss']
})
export class BookEventComponent implements OnInit {

  eventBookForm: FormGroup;
  submitted = false;
  minDate = new Date();

  constructor(
    private _dialogRef: MatDialogRef<BookEventComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private _fb: FormBuilder,
    private _eventsService: EventsService,
  ) {

  }
  ngOnInit() {
    this.eventBookForm = this._fb.group ({
      firstName: [ '', [Validators.required, this.noWhitespace]],
      lastName: [ '', [Validators.required, this.noWhitespace]],
      email: [ '', [Validators.required, Validators.email]],
      phoneNumber: [ '', [Validators.required, Validators.pattern(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/)]],
      createdTime: [new Date()]
    });
  }

  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true }
  }

  async bookEvent(): Promise<void> {
    this.submitted = true;

    if (this.eventBookForm.valid) {
      this._eventsService.newEventRequest(this.eventBookForm.value).then((data) => {
        if (data) {
          this.eventBookForm.reset();
          this._dialogRef.close();
        }
      })
    }
    return;
  }

  dialogClose() {
    this._dialogRef.close();
  }
  get firstName() {
    return this.eventBookForm.get('firstName');
  }
  get lastName() {
    return this.eventBookForm.get('lastName');
  }
  get email() {
    return this.eventBookForm.get('email');
  }
  get phoneNumber() {
    return this.eventBookForm.get('phoneNumber');
  }
}
