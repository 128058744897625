import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { WaiverFormComponent } from 'src/app/modules/home/components/waiver-form/waiver-form.component';
import { UsersService } from 'src/app/modules/dashboard/services/users.service';
import { AuthService } from 'src/app/core/auth.service';
import { ToastService } from 'src/app/core/service/toast/toast.service';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-sign-wavier',
  templateUrl: './sign-wavier.component.html',
  styleUrls: ['./sign-wavier.component.scss']
})
export class SignWavierComponent implements OnInit, AfterViewInit {
  constructor(
    private service: UsersService,
    private auth: AuthService,
    private toast: ToastService,
    private fb: FormBuilder
  ) { }
  pad: SignaturePad;
  profile: any;
  previousData;
  userForm: FormGroup;
  isLoggedIn = false;
  todaysDate = new Date();
  customTabs = ['Adult Waiver', 'Minor Waiver'];
  activeControlPanel = 'Adult Waiver';
  signaturePad;
  tags: Array<any> = [];
  ngOnInit() {
    const emailRegex = new RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const phoneRegex = new RegExp(
      /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
    );
    this.userForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern(emailRegex)]],
      phoneNo: [null, [Validators.required, Validators.pattern(phoneRegex)]],
      emergencyPhone: [
        null,
        [Validators.required, Validators.pattern(phoneRegex)]
      ],
      emergencyContact: [null, [Validators.required]],
      userId: [null],
      guardianName: [null],
      guardianPhone: [null],
      relation: [null],
      isRead: [null, Validators.required],
      sign: [null, Validators.required],
      userTags: [null, Validators.required],
      isMinor: [null, Validators.required],
      password: [(Math.floor(100000 + Math.random() * 900000)).toString()],
    });
    this.auth.profile.subscribe(async (data: any) => {
      console.log(data);
      if (data) {
        this.isLoggedIn = true;
        this.customTabs = ['Adult Waiver'];
        this.userForm.get('firstName').setValue(data.firstName);
        this.userForm.get('lastName').setValue(data.lastName);
        this.userForm.get('email').setValue(data.email);
        this.userForm.get('phoneNo').setValue(data.phoneNo);
        this.userForm.get('userId').setValue(data.id);
        this.userForm.get('isRead').setValue(data.isWavierSigned);
        this.userForm.get('isMinor').setValue(data.isMinor);
        this.userForm.get('emergencyPhone').setValue(data.emergencyPhone);
        this.userForm.get('emergencyContact').setValue(data.emergencyContact);
        this.userForm.get('sign').setValue(data.sign);
        if (this.signaturePad) {
          this.signaturePad.fromData(this.sign.value);
        }
      } else {
      }
      // this.profile = data;
      // this.previousData = this.profile.wavierData;
    });

    this.auth.getTags().subscribe((data) => {
      this.tags = data;
    });

    this.emergencyContact.valueChanges.subscribe(data => {
      if (this.isLoggedIn) {
        this.signaturePad.clear();
      }
    });

    this.emergencyPhone.valueChanges.subscribe(data => {
      if (this.isLoggedIn) {
        this.signaturePad.clear();
      }
    });
  }

  wavierTabChange() {
    console.log(this.isMinor.invalid);
    if (!this.isLoggedIn) {
      this.resetForm();
    }
    console.log(this.activeControlPanel);
    if (this.activeControlPanel === 'Adult Waiver') {
      this.isMinor.setValue(null);
      this.isMinor.setValidators(Validators.required);
      this.emergencyPhone.setValidators(Validators.required);
      this.relation.clearValidators();
      this.relation.updateValueAndValidity();
      console.log(this.relation);
    } else {
      this.isMinor.setValue('yes');
      this.relation.setValidators(Validators.required);
      this.emergencyPhone.setErrors(null);
      this.isMinor.setErrors(null);
    }
    console.log(this.relation);
    console.log(this.relation.valid);
  }

  resetForm() {
    this.userForm.reset();
    this.signaturePad.clear();
  }

  ngAfterViewInit() {
    var canvas = document.querySelector('canvas');
    console.log(canvas);
    this.signaturePad = new SignaturePad(canvas);
    if (this.sign.value) {
      this.signaturePad.fromData(this.sign.value);
    }
  }

  async submit() {
    console.log(this.userForm);
    if (!this.signaturePad.isEmpty()) {
      this.sign.setValue(this.signaturePad.toData());
      // this.sign.setErrors(null);
    } else {
      this.toast.openSnackBar('Please Sign The Form');
      this.userForm.markAllAsTouched();
      return;
    }
    if (this.userForm.valid) {
      if (!this.isRead.value) {
        this.toast.openSnackBar('Please Accept Terms And Conditions');
        return;
      }
      if (this.isLoggedIn) {
        await this.service.updateUser(
          {
            isWavierSigned: true,
            sign: this.sign.value,
            emergencyContact: this.emergencyContact.value,
            emergencyPhone: this.emergencyPhone.value,
            wavierSignedOn: new Date(),
            userTags: this.userForm.get('userTags').value
          },
          this.userForm.get('userId').value,
          true
        );
        this.dialogClose();
      } else {
        const user = this.userForm.value;
        if (this.activeControlPanel === 'Adult Waiver') {
          user.isMinor = 'no';
        }
        user.isWavierSigned = true;
        user.wavierSignedOn = new Date();
        user.isSubEnabled = false;
        user.createdDate = new Date();
        user.isBlocked = false;
        user.profileImage = null;
        user.email = user.email.toLowerCase();
        user.userTags = this.userForm.get('userTags').value;
        delete user.isRead;
        if (user.isMinor === 'no') {
          const isExists = await this.service
            .checkEmailAndPhone(user.email, user.phoneNo)
            .toPromise();
          console.log(isExists);
          if (isExists[0].length > 0) {
            this.toast.openSnackBar('Email Already Exits ');
            return;
          } else if (isExists[1].length > 0) {
            this.toast.openSnackBar('Phone No Already Exits ');
            return;
          }
        }
        this.service.getUserId(user, true).then(data => {
          // this._dialogRef.close();
          if (!this.isLoggedIn) {
            this.resetForm();
          }
        });
        // this.toast.openSnackBar('Please Sign The Wavier Form');
      }
    } else {
      this.toast.openSnackBar('Enter Valid Form');
      this.userForm.markAllAsTouched();
    }
  }
  dialogClose() {
    // this._dialogRef.close();
  }

  get phoneNo() {
    return this.userForm.get('phoneNo');
  }
  get emergencyPhone() {
    return this.userForm.get('emergencyPhone');
  }
  get emergencyContact() {
    return this.userForm.get('emergencyContact');
  }
  get relation() {
    return this.userForm.get('relation');
  }
  get email() {
    return this.userForm.get('email');
  }
  get isRead() {
    return this.userForm.get('isRead');
  }
  get isMinor() {
    return this.userForm.get('isMinor');
  }
  get sign() {
    return this.userForm.get('sign');
  }
}
