import { SecuredLsService } from './../../../core/secured-ls.service';
import {
  Users,
  generalEnquiries,
  HomePageSlider,
  User,
  Enquiry
} from './../models/user';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { ToastService } from 'src/app/core/service/toast/toast.service';
import { Observable, combineLatest, merge, zip, forkJoin } from 'rxjs';
import { Sport } from '../models/sport';
import { take, switchMap, map, combineAll } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private _afs: AngularFirestore,
    private _fb: FormBuilder,
    private _toast: ToastService,
    private sls: SecuredLsService
  ) { }

  /* --------------------------- UserId Transaction -------------------------- */
  async getUserId(user: User, isWavier?: boolean): Promise<any> {
    let userId;
    const userRef = this._afs.doc(`userIds/userId`).ref;
    this._afs.firestore
      .runTransaction(transaction => {
        return transaction.get(userRef).then(async orderId => {
          if (!orderId.exists) {
            throw new Error('Document does not exist!');
          }
          const userDocId = parseInt(orderId.data().id.slice(1));
          const id = userDocId + 1;
          userId = `U${id}`;
          transaction.update(userRef, { id: userId });
        });
      })
      .then(async data => {
        console.log(data);
        console.log('transaction complete');
        return this.addUser(user, userId, isWavier);
      });
  }

  /* ----------------------- Getting All User Enquiries By Status ----------------------- */
  getAllUserEnquiryByStatus(status: string): Observable<Array<Enquiry>> {
    console.log(this.sls.get('user').uid);
    console.log(status);
    return this._afs
      .collection<Enquiry>('userEnquiry', ref =>
        ref
          .where('status', '==', status)
          .where('userId', '==', this.sls.get('user').uid)
          .orderBy('createdTime', 'desc')
      )
      .valueChanges();
  }

  /* ------------------------------- Adding User ------------------------------ */
  async addUser(user: User, userId: string, isWavier?: boolean): Promise<boolean> {
    // console.log(user);
    try {
      user.id = userId;
      user.emailConfirmed = false;
      user.name = `${user.firstName} ${user.lastName}`;
      user.uId = this._afs.createId();
      const payload = {
        id: this._afs.createId(),
        action: 'user',
        message: 'New User Registered',
        createdOn: new Date(),
        isRead: false
      };
      const batch = this._afs.firestore.batch();
      const ref = this._afs.doc(`adminNotification/${payload.id}`).ref;
      const userRef = this._afs.doc(`user/${userId}`).ref;
      batch.set(ref, payload);
      batch.set(userRef, user);

      await batch.commit();
      if (isWavier) {
        this._toast.openSnackBarSpecial(
          `Thank you for signing the online waiver. Please check your inbox to finish the waiver signing process.
          Please check your Spam folder if not present in the inbox.`
        );
      } else {
        this._toast.openSnackBarSpecial(
          `Thank you for signing up. We sent you an email with an activation link. Please check your inbox.
          Please check your Spam folder if not present in the inbox.`
        );
      }

      // console.log('User Added Successfully');
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  sendNotification() {
    try {
      const ref = this._afs.doc(`adminNotification/${this._afs.createId()}`)
        .ref;
    } catch (error) { }
  }

  /* ------------------------------- UpdateUser ------------------------------- */
  async updateUser(
    user: any,
    userId: string,
    wavier?: boolean
  ): Promise<boolean> {
    // console.log(user);
    try {
      if (user.firstName && user.lastName) {
        user.name = `${user.firstName} ${user.lastName}`;
      }
      const batch = this._afs.firestore.batch();
      if (wavier) {
        const payload = {
          id: this._afs.createId(),
          action: 'user',
          message: 'Wavier Signed',
          createdOn: new Date(),
          isRead: false
        };
        const ref = this._afs.doc(`adminNotification/${payload.id}`).ref;
        batch.set(ref, payload);
      }
      console.log(user);
      batch.update(this._afs.doc(`user/${userId}`).ref, user);
      await batch.commit();
      this._toast.openSnackBar('User Updated Successfully');
      // console.log('User Added Successfully');
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async newGeneralEnquiry(enquiry: generalEnquiries): Promise<boolean> {
    // console.log(user);
    try {
      enquiry.id = this._afs.createId();
      await this._afs.doc(`userEnquiry/${enquiry.id}`).set(enquiry);
      this._toast.openSnackBar(
        'Thanks for contacting us. Will get back shortly.'
      );
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  getSliders(): Observable<HomePageSlider[]> {
    return this._afs
      .collection<HomePageSlider>('homePageSlider')
      .valueChanges();
  }
  getCommonThings(doc: string): Observable<any> {
    return this._afs.doc(`commonThings/${doc}`).valueChanges();
  }
  getFaqs(): Observable<any> {
    return this._afs.collection(`faq`).valueChanges();
  }
  getAllCoupon(plan, email): Observable<any> {
    console.log(plan);
    return this._afs
      .collection(`coupon`, ref => ref.where(plan, '==', true))
      .valueChanges()
      .pipe(
        switchMap((data: any) => {
          console.log(data);
          const usedCount: Array<Observable<any>> = [];
          data.forEach(data => {
            if (data.couponCode) {
              usedCount.push(
                this._afs
                  .collection('couponTransaction', ref =>
                    ref
                      .where('couponCode', '==', data.couponCode)
                      .where('email', '==', email)
                  )
                  .valueChanges()
                  .pipe(
                    map(used => {
                      console.log(used);
                      return { ...data, used: used ? used.length : 0 };
                    })
                  )
              );
            }
          });
          return combineLatest(usedCount);
        })
      );
  }

  getAllCategory(): Observable<Array<any>> {
    return this._afs
      .collection<any>('category', ref => ref.orderBy('name'))
      .valueChanges();
  }

  getOrdersByUserId(userId: string): Observable<any> {
    console.log(userId);
    return this._afs
      .collection('bookingTransaction', ref =>
        ref.where('userId', '==', userId)
      )
      .valueChanges();
  }

  getAllSports(): Observable<Array<Sport>> {
    return this._afs.collection<Sport>('sports').valueChanges();
  }

  checkEmailAndPhone(email, phone): Observable<any> {
    console.log(email, phone);
    const emailCheck = this._afs
      .collection('user', ref => ref.where('email', '==', email).where('isMinor', '==', "no"))
      .valueChanges()
      .pipe(take(1));
    const phoneCheck = this._afs
      .collection('user', ref => ref.where('phoneNo', '==', phone).where('isMinor', '==', 'no'))
      .valueChanges()
      .pipe(take(1));
    return forkJoin([emailCheck, phoneCheck]);
  }
}
