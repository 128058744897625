import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
import { DatePipe } from '@angular/common';
import { MatProgressSpinnerModule, MatSnackBarModule } from '@angular/material';
import { HomeModule } from './modules/home/home.module';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LiveTvModule } from './modules/live-tv/live-tv.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    HttpClientModule,
    HomeModule,
    MarkdownModule.forRoot({
      loader: HttpClient, // optional, only if you use [src] attribute
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          gfm: true,
          tables: true,
          breaks: false,
          pedantic: false,
          sanitize: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    LiveTvModule,
  ],
  providers: [DatePipe, { provide: StorageBucket, useValue: 'gs://cric-indo.appspot.com/' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
