import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { SecuredLsService } from './secured-ls.service';

import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { ToastService } from './service/toast/toast.service';
import { User } from '../modules/dashboard/models/user';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /* --------------------------------- Fields --------------------------------- */

  loggedIn: BehaviorSubject<boolean>;
  profile: BehaviorSubject<User>;
  userId;
  user;
  /* ------------------------------- Constructor ------------------------------ */

  constructor(
    private readonly _afAuth: AngularFireAuth,
    private readonly _router: Router,
    private readonly _sls: SecuredLsService,
    private readonly _toast: ToastService,
    private _afs: AngularFirestore
  ) {
    /* ----------------------------- Custom Methods ----------------------------- */

    // we subscribe to the authentication state; if the user is logged in,
    // we add the user's data to the browser's local storage; otherwise we store a undefined user
    const role = this._sls.get('role').length > 0 ? true : false;
    const pro =
      this._sls.get('profile').length > 0
        ? JSON.parse(this._sls.get('profile'))
        : null;
    this.loggedIn = new BehaviorSubject(role);
    this.profile = new BehaviorSubject(pro);
    this._afAuth.authState.subscribe(user => {
      console.log(user);
      if (user) {
        this.user = user;

        console.log(this.user);
        this._sls.set('user', user);
        user.getIdTokenResult().then(idTokenResult => {
          // console.log(this._sls.get('user'));
          this.loggedIn.next(true);
          this.getProfile(user.uid);
          if (!!idTokenResult.claims.role) {
            this._sls.set('role', idTokenResult.claims.role);
          } else {
            // this._sls.set('userRole', 'admin');
          }
        });
      } else {
        // this._toast.openSnackBar('Something Went Wrong Please Login Again');
        this.signOut(true);
      }
    });
  }

  getProfile(uId) {
    this._afs
      .doc<User>(`user/${uId}`)
      .valueChanges()
      .subscribe(data => {
        console.log(data);
        if (!data) {
          this.signOut(true);
          this._toast.openSnackBar(
            'Something Went Please Login Again Or Try Contacting Admin'
          );
          return;
        }
        this._sls.set('profile', JSON.stringify(data));
        if (data.wavierSignedOn && !(data instanceof Date)) {
          data.wavierSignedOn = data.wavierSignedOn.toDate();
        }
        this.profile.next(data);
      });
  }

  // Login Status
  // isLoggedIn(): boolean {
  //   return this.user ? true : false;
  // }

  async checkEmailVerification(email): Promise<string> {
    try {
      const response = await this._afs
        .collection<User>(`user`, ref => ref.where('email', '==', email).where('isMinor', '==', 'no'))
        .valueChanges()
        .pipe(take(1))
        .toPromise();

      if (response.length === 0) {
        return 'User email id not found. Please make sure you are using the correct email ID';
      } else if (!response[0].emailConfirmed) {
        this.userId = response[0].id;
        return 'Please confirm your email and try again. Please Click On Resend Activation Link below to receive the link to your email';
      } else if (response[0].isBlocked) {
        return 'Your Account Has Been Blocked Please Contact Admin';
      } else if (response.length > 1) {
        return 'Something Went Wrong Please Contact Admin';
      }
      this.userId = response[0].id;
      return null;
    } catch (err) {
      console.log(err);
      return 'Something Went Wrong PLease Try Again Later';
    }
  }

  async emailLogin(
    email: string,
    password: string
  ): Promise<{ success: boolean; message: string; userId?: string }> {
    try {
      const response = await this.checkEmailVerification(email);
      if (response) {
        this._toast.openSnackBarSpecial(response);
        return { success: false, message: response, userId: this.userId };
      }
      await this._afAuth.auth.signInWithEmailAndPassword(email, password);
      this._toast.openSnackBar('Login Successfully');
      return { success: true, message: null };
    } catch (err) {
      console.log(err);
      if (err.code === 'auth/user-not-found') {
        this._toast.openSnackBar('No User Found');
      } else if (err.code === 'auth/wrong-password') {
        this._toast.openSnackBar('Password Mismatch');
      } else {
        this._toast.openSnackBar('Invalid Credentials');
      }
      return { success: false, message: null };
    }
  }

  // Resets current user password if exists
  async resetPassword(email: string): Promise<void> {
    const fbAuth = auth();
    try {
      await fbAuth.sendPasswordResetEmail(email);
      this._toast.openSnackBar('Password Reset Link Sent - Check your email');
      return;
    } catch (error) {
      console.log(`Reset Password: ${error}`);
      this._toast.openSnackBar('Please enter correct email');

      return;
    }
  }

  async resendActivationLink(email: string, userId: string): Promise<void> {
    const fbAuth = auth();
    try {
      const payload = {
        id: this._afs.createId(),
        email,
        createdDate: new Date(),
        userId
      };
      await this._afs.doc(`resendLink/${payload.id}`).set(payload);
      this._toast.openSnackBar(`Email Activation Link Sent - Check your email.
      Please check your Spam folder if not present in the inbox.
      `);
      return;
    } catch (error) {
      console.log(`Reset Password: ${error}`);
      this._toast.openSnackBar('Please try again later');

      return;
    }
  }

  // Signs User Out
  async signOut(toast?: boolean): Promise<void> {
    try {
      await this._afAuth.auth.signOut();
      const data: Array<string> = this._sls.getAllKeys();
      console.log(data);
      this.loggedIn.next(false);
      this.profile.next(null);
      data.forEach(key => {
        if (key !== 'loginEmail' && key !== 'loginPassword') {
          this._sls.removeKeys(key);
        }
      });
      //  this._sls.removeAllKeys();
      if (!toast) {
        this._toast.openSnackBar('Signed Out');
        this._router.navigate(['home']);
      }
    } catch (error) {
      this.loggedIn.next(true);
      console.log(`Sign out error: ${error}`);
      this._toast.openSnackBar('Unable to sign out');

      return;
    }
  }

  async changePassword(newPassword, oldPassword): Promise<boolean> {
    try {
      console.log(this.user);
      const response = await this._afAuth.auth.signInWithEmailAndPassword(this.user.email, oldPassword);
      console.log(response);
      await this.user.updatePassword(newPassword);
      this._toast.openSnackBar('Password Reset Successfully');
      return true;
    } catch (err) {
      console.log(err);
      this._toast.openSnackBar('Password Updating Failed');
      return false;
    }
  }

  getTags(): Observable<any> {
    return this._afs.collection('tags').valueChanges();
  }
}
