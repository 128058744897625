import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from './../../core/service/toast/toast.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormGroupDirective
} from '@angular/forms';
import { Users, Login, User } from 'src/app/modules/dashboard/models/user';
import { MatDialogRef, MAT_DIALOG_DATA, MatTabGroup } from '@angular/material';
import { SignInComponent } from 'src/app/modules/dashboard/components/sign-in/sign-in.component';
import { UsersService } from 'src/app/modules/dashboard/services/users.service';
import { AuthService } from 'src/app/core/auth.service';
import { SecuredLsService } from 'src/app/core/secured-ls.service';
import { Router } from '@angular/router';
import { FileProperties } from '../models/file';

@Component({
  selector: 'app-login-signup',
  templateUrl: './login-signup.component.html',
  styleUrls: ['./login-signup.component.scss']
})
export class LoginSignupComponent implements OnInit {
  minDate = new Date();
  signUpForm: FormGroup;
  userSignUpForm: Users;
  signInForm: FormGroup;
  submitted = false;
  showEmailReActive = false;
  resendTo = null;
  @ViewChild(MatTabGroup, { static: false }) public tab: MatTabGroup;
  cpWord: FormControl = new FormControl(null, Validators.required);
  isPasswordRemember: FormControl = new FormControl(false);
  type = 'login';
  /* ----------------------------- File Properties ---------------------------- */

  imageUrlProperties: FileProperties = {
    folderName: 'images',
    acceptedFileTypes: ['image/jpeg', 'image/png'],
    maxSize: 30
  };
  load: boolean;

  constructor(
    private _dialogRef: MatDialogRef<SignInComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private _fb: FormBuilder,
    private _usersService: UsersService,
    private _authService: AuthService,
    private _sls: SecuredLsService,
    private _router: Router,
    private toast: ToastService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    const email = this._sls.get('loginEmail');
    const password = this._sls.get('loginPassword');
    const emailRegex = new RegExp(
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    );
    const phoneRegex = new RegExp(
      /\(?\d{3}\)?\d{3}\d{4}/g
    );


    this.signUpForm = this._fb.group({
      id: [],
      uId: [],
      createdDate: [],
      email: ['', [Validators.required, Validators.pattern(emailRegex)]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      isBlocked: [false],
      phoneNo: [null, [Validators.required, Validators.pattern(phoneRegex)]],
      emergencyPhone: [null],
      emergencyContact: [null],
      isWavierSigned: [false],
      isSubEnabled: [false],
      guardianName: [null],
      guardianPhone: [null],
      password: ['', [Validators.required, Validators.minLength(5)]],
      profileImage: [''],
      isMinor: [null, Validators.required],
      isTermsRead: [null, Validators.required]
    });
    this.signInForm = this._fb.group({
      uemail: ['', [Validators.required, Validators.pattern(emailRegex)]],
      upassword: ['', Validators.required]
    });

    if (email.length > 0 && password.length > 0) {
      console.log(email);
      this.isPasswordRemember.setValue(true);
      this.uemail.setValue(email);
      this.upassword.setValue(password);
    }
    this.isMinor.valueChanges.subscribe(data => {
      if (data) {
        this.signUpForm.get('isMinor').setErrors(null);
      } else {
        this.signUpForm.get('isMinor').setErrors({ 'not-valid': true });
      }
    });
    this.isRead.valueChanges.subscribe(data => {
      if (data) {
        this.signUpForm.get('isRead').setErrors(null);
      } else {
        this.signUpForm.get('isRead').setErrors({ 'not-valid': true });
      }
    });

    this.signUpForm.get('emergencyPhone').valueChanges.subscribe(data => {
      if (data) {
        this.signUpForm
          .get('emergencyPhone')
          .setValidators(Validators.pattern(phoneRegex));
      } else {
        this.signUpForm.get('emergencyPhone').setErrors(null);
      }
    });

    this.cpWord.valueChanges.subscribe(data => this.checkPassword());
    this.password.valueChanges.subscribe(() => this.checkPassword());
  }
  dialogClose() {
    this._dialogRef.close();
  }

  checkPassword() {
    if (this.password.value) {
      if (this.password.value === this.cpWord.value) {
        this.cpWord.setErrors(null);
      } else {
        this.cpWord.setErrors({ misMatch: true });
      }
    }
  }

  async userSignUp(formDirective: FormGroupDirective): Promise<void> {
    if (this.signUpForm.valid && this.cpWord.valid) {
      const user: any = this.signUpForm.value;

      if (this.isMinor.value === false) {
        this.toast.openSnackBar('Please Accept 18 Years And Above');
        return;
      }
      if (user.isTermsRead === false) {
        this.toast.openSnackBar('Please Accept  Terms And Condition');
        return;
      }
      user.createdDate = new Date();
      // const data = this._usersService
      //   .checkEmailAndPhone(user.email, user.phoneNo)
      //   .toPromise();
      user.email = user.email.toLowerCase();
      const isExists = await this._usersService
        .checkEmailAndPhone(user.email, user.phoneNo)
        .toPromise();
      console.log(isExists);
      if (isExists[0].length > 0) {
        this.toast.openSnackBar('Email Already Exits ');
        return;
      } else if (isExists[1].length > 0) {
        this.toast.openSnackBar('Phone No Already Exits ');
        return;
      }
      user.isMinor = 'no';
      this.spinner.show();
      this._usersService.getUserId(user).then(data => {
        this.tab.selectedIndex = 0;
        formDirective.resetForm();
        this.signUpForm.reset();
        this.cpWord.reset();
        this.spinner.hide(); // this._dialogRef.close();
      });
    } else {
      this.signUpForm.markAllAsTouched();
      this.cpWord.markAllAsTouched();
      this.toast.openSnackBar('Enter Valid Form');
    }
  }

  async login(): Promise<void> {
    this.submitted = true;
    if (this.signInForm.valid) {
      const login = this.signInForm.value as Login;
      login.uemail = login.uemail.toLowerCase();
      try {
        if (this.isPasswordRemember.value) {
          console.log(this.email.value);
          this._sls.set('loginEmail', this.uemail.value.toLowerCase());
          this._sls.set('loginPassword', this.upassword.value);
        } else {
          this._sls.removeKeys('loginEmail');
          this._sls.removeKeys('loginPassword');
        }
        this.spinner.show();
        const response = await this._authService.emailLogin(
          login.uemail,
          login.upassword
        );
        this.spinner.hide();
        if (response.success) {
          this._dialogRef.close();
        }
        if (
          response.message ===
          'Please confirm your email and try again. Please Click On Resend Activation Link below to receive the link to your email'
        ) {
          this.showEmailReActive = true;
          this.resendTo = response.userId;
        } else {
          this.showEmailReActive = false;
          this.resendTo = null;
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide();
        // this._toastService.openSnackBar(`Invalid Login Credentials`);
        return;
      }
    }
  }

  async resetPassword() {
    await this._authService.resetPassword(this.uemail.value);
  }
  async resendActivationLink() {
    console.log(this.uemail.value);
    await this._authService.resendActivationLink(
      this.uemail.value,
      this.resendTo
    );
  }

  /* --------------------------------- Getters -------------------------------- */

  get firstName() {
    return this.signUpForm.get('firstName');
  }
  get lastName() {
    return this.signUpForm.get('lastName');
  }
  get email() {
    return this.signUpForm.get('email');
  }
  get isMinor() {
    return this.signUpForm.get('isMinor');
  }
  get isRead() {
    return this.signUpForm.get('isTermsRead');
  }
  get password() {
    return this.signUpForm.get('password');
  }
  get profileImage() {
    return this.signUpForm.get('profileImage');
  }
  get phoneNo() {
    return this.signUpForm.get('phoneNo');
  }

  /* --------------------------------- Getters -------------------------------- */

  get uemail() {
    return this.signInForm.get('uemail');
  }
  get upassword() {
    return this.signInForm.get('upassword');
  }
}
