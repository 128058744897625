import { ToastService } from './../../../../core/service/toast/toast.service';
import { AuthService } from './../../../../core/auth.service';
import { User } from './../../../dashboard/models/user';
import { UsersService } from './../../../dashboard/services/users.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import SignaturePad, * as signature from 'signature_pad';
import { MatDialogRef } from '@angular/material';
@Component({
  selector: 'app-waiver-form',
  templateUrl: './waiver-form.component.html',
  styleUrls: ['./waiver-form.component.scss']
})
export class WaiverFormComponent implements OnInit,AfterViewInit {

  @ViewChild('sign',{static:false}) public sign: ElementRef;
  constructor(private _dialogRef: MatDialogRef<WaiverFormComponent>,
    private service: UsersService , private auth: AuthService, private toast: ToastService) { }
  pad: SignaturePad;
  profile:any;
  previousData;

  ngOnInit() {
  this.auth.profile.subscribe((data)=>{
   this.profile = data;
   this.previousData=this.profile.wavierData;
  });
  }

  ngAfterViewInit(){
    console.log(this.sign);
    this.pad = new  SignaturePad(this.sign.nativeElement);
    if(!!this.previousData){
         this.pad.fromData(this.previousData);
    }
    console.log(this.pad);
  }

  async submit() {
  const data = this.pad.toData();
  if(!this.pad.isEmpty()){
  if(!!this.profile){
  await this.service.updateUser({wavierData:this.pad.toData()},this.profile.id);
  }
  this.dialogClose();
} else{
  this.toast.openSnackBar('Please Sign The Wavier Form');
}
  // this.pad.clear();
  // setTimeout(() => {
  // this.pad.fromData(data);
  // }, 2000);
  }
  dialogClose() {
    this._dialogRef.close();
  }
}
