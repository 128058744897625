import { Coupon, User } from './../../dashboard/models/user';
import { ToastService } from './../../../core/service/toast/toast.service';
import { FormBuilder } from '@angular/forms';
import { EventRequests, Bookings, Sport, Category } from './../models/events';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs';
import { switchMap, map, take } from 'rxjs/operators';
import { startOfDay, subDays } from 'date-fns';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { firestore } from 'firebase';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private _afs: AngularFirestore,
    private _fb: FormBuilder,
    private _toast: ToastService,
    private _http: HttpClient
  ) {}

  monthSelected: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  getAllSports(): Observable<Array<Sport>> {
    return this._afs
      .collection<Sport>('sports', (ref) =>
        ref.where('isReservationEnabled', '==', true)
      )
      .valueChanges()
      .pipe(
        switchMap((sports) => {
          console.log(sports);
          const sportsObserver: Array<Observable<Sport>> = [];
          sports.forEach((sport) =>
            sportsObserver.push(
              this._afs
                .doc<Category>(`category/${sport.categoryId}`)
                .valueChanges()
                .pipe(
                  map((category) => {
                    sport.category = category;
                    return sport;
                  })
                )
            )
          );
          return combineLatest(sportsObserver);
        })
      );
  }

  getUserCheckInByDateAndSport(
    sportSelected: string,
    userId: string
  ): Observable<Array<any>> {
    return this._afs
      .collection<User>('bookingCheckIn', (ref) =>
        ref
          .where('date', '==', startOfDay(new Date()))
          .where('sportSelected', '==', sportSelected)
          .where('userId', '==', userId)
      )
      .valueChanges()
      .pipe(take(1));
  }

  getHomeDetail(): Observable<any> {
    return this._afs.doc(`home/home`).valueChanges();
  }

  getAllSponsors(): Observable<Array<any>> {
    return this._afs.collection('sponsors').valueChanges();
  }
  getSlotsForMonth(month): Observable<any> {
    return this._afs
      .collection('slotsMaster', (ref) => ref.where('month', '==', month))
      .valueChanges()
      .pipe(
        switchMap((masters) =>
          this._afs
            .collection('slotsTransaction', (ref) =>
              ref.where('month', '==', month)
            )
            .valueChanges()
            .pipe(
              map((trns) => {
                return { master: masters, transaction: trns };
              })
            )
        )
      );
  }
  getSlotsByDay(date: Date, day: string, month: any): Observable<any> {
    console.log(day);
    return this._afs
      .collection('slotsTransaction', (ref) => ref.where('date', '==', date))
      .valueChanges()
      .pipe(
        switchMap((slots) => {
          console.log(slots);
          if (slots.length === 0) {
            return this._afs
              .collection('slotsMaster', (ref) =>
                ref.where('day', '==', day).where('month', '==', month)
              )
              .valueChanges();
          }
          return of(slots);
        })
      );
  }

  getSlotCoupon(): Observable<Array<Coupon>> {
    const startDate = startOfDay(new Date());
    return this._afs
      .collection<Coupon>('coupon', (ref) =>
        ref
          .where('toDate', '>=', startDate)
          .where('couponType', '==', 'General')
      )
      .valueChanges()
      .pipe(
        map((coupons) => {
          console.log(coupons);
          coupons = coupons.filter(
            (data) => data.fromDate.toDate() <= startDate
          );
          return coupons;
        })
      );
  }

  getAvailability(month: any, sportId: string): Observable<any> {
    console.log(month);
    return this._afs
      .collection('slotsTransaction', (ref) => ref.where('month', '==', month))
      .valueChanges()
      .pipe(
        switchMap((transactions) =>
          this._afs
            .collection('slotsMaster', (ref) =>
              ref.where('sportId', '==', sportId).where('month', '==', month)
            )
            .valueChanges()
            .pipe(
              map((master) => {
                return { master, transactions };
              })
            )
        )
      );
  }
  /* ------------------------ Get Coupon By Coupon Code ----------------------- */
  getCouponCode(couponCode: string): Promise<Array<Coupon>> {
    return this._afs
      .collection<Coupon>('coupon', (ref) =>
        ref.where('couponCode', '==', couponCode)
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  getAllBookedSlotPerDay(): Observable<Array<Bookings>> {
    return this._afs
      .collection<Bookings>('bookingTransaction', (ref) =>
        ref.where('bookedDates', 'array-contains', startOfDay(new Date()))
      )
      .valueChanges();
  }
  /* ------------------------ Get Coupon  Transaction By Coupon Code ----------------------- */
  getCouponTransactionCode(couponCode: string): Promise<Array<Coupon>> {
    return this._afs
      .collection<Coupon>('couponTransaction', (ref) =>
        ref.where('couponCode', '==', couponCode)
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }
  /* ------------------------ Get Coupon  Transaction By Coupon Code ----------------------- */
  getCouponTransactionCodeUser(
    couponCode: string,
    email: string
  ): Promise<Array<Coupon>> {
    return this._afs
      .collection<Coupon>('couponTransaction', (ref) =>
        ref.where('couponCode', '==', couponCode).where('email', '==', email)
      )
      .valueChanges()
      .pipe(take(1))
      .toPromise();
  }

  async newEventRequest(request: EventRequests): Promise<boolean> {
    try {
      request.id = this._afs.createId();
      await this._afs.doc(`eventRequests/${request.id}`).set(request);
      // this._toast.openSnackBar('Item Added Successfully');
      console.log('New Request Posted Successfully');
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async checkIn(checkIn: any, bookingId): Promise<boolean> {
    try {
      await this._afs
        .doc(`bookingTransaction/${bookingId}`)
        .update({ checkIn });
      // this._toast.openSnackBar('Item Added Successfully');
      this._toast.openSnackBar('CheckIn Saved Successfully');
      return true;
    } catch (error) {
      console.log(error);
      this._toast.openSnackBar('CheckIn Saving Failed');
      return false;
    }
  }

  getAllAvailableSports(): Observable<Array<Sport>> {
    return this._afs.collection<Sport>('sports').valueChanges();
  }

  async multipleCheckIn(checkIn: any): Promise<boolean> {
    try {
      await this._afs.doc(`bookingCheckIn/${checkIn.id}`).set(checkIn);
      // this._toast.openSnackBar('Item Added Successfully');
      this._toast.openSnackBar('CheckIn Saved Successfully');
      return true;
    } catch (error) {
      console.log(error);
      this._toast.openSnackBar('CheckIn Saving Failed');
      return false;
    }
  }

  async paymentGateway(payload) {
    try {
      payload.id = this._afs.createId();
      await this._afs.doc(`paymentGateway/${payload.id}`).set(payload);
      return payload.id;
    } catch (err) {
      console.log(err);
    }
  }

  async bookSlot(nonce, id): Promise<any> {
    try {
      const header = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'fbd13b166edd23de7e682d7f12ee5d47d3484f35',
        }),
      };
      const res = await this._http
        .post(
          `https://us-central1-cric-indo.cloudfunctions.net/api/indo/posts`,
          { nonce, id },
          header
        )
        .toPromise();
      return { status: true, response: res };
    } catch (err) {
      console.log(err);
      return { status: false, error: err };
    }
  }

  getUserDetails() {
    return this._afs.doc('user/VWlq4SnAJEDwio93nZs7').valueChanges();
  }

  async getUserDetailss(user) {
    await this._afs.doc('user/U1145').set(user);
  }

  /* --------------------------- OrderId Transaction -------------------------- */
  async getOrderId(
    booking: any,
    selectedSlots: Array<any>,
    couponTransaction: any
  ): Promise<boolean> {
    try {
      let orderBillNo;
      const batch = this._afs.firestore.batch();
      let orderRef = this._afs.doc(`orders/orderId`).ref;
      console.log(couponTransaction);
      return this._afs.firestore
        .runTransaction((transaction) => {
          return transaction.get(orderRef).then(async (orderId) => {
            if (!orderId.exists) {
              throw new Error('Document does not exist!');
            }
            const bookDocId = parseInt(orderId.data().id.slice(1));
            const id = bookDocId + 1;
            orderBillNo = `B${id}`;
            transaction.update(orderRef, { id: orderBillNo });
          });
        })
        .then(async (data) => {
          console.log(data);
          await this.newBookingConfirm(
            booking,
            selectedSlots,
            couponTransaction,
            orderBillNo
          );
          console.log('transaction complete');
          return true;
        })
        .catch((err) => {
          this._toast.openSnackBar('Please Contact Admin For Refund');
          return false;
        });
    } catch (err) {
      this._toast.openSnackBar('Please Contact Admin For Refund');
      console.log(err);
      return false;
    }
  }

  /* ------------------------- Confirming New Booking ------------------------- */
  async newBookingConfirm(
    booking: any,
    selectedSlots: Array<any>,
    couponTransaction: any,
    orderId: number
  ): Promise<boolean> {
    try {
      const payload = {
        id: this._afs.createId(),
        action: 'booking',
        createdOn: new Date(),
        message: 'Slots Has Been Booked',
        isRead: false,
      };
      const batch = this._afs.firestore.batch();
      console.log(selectedSlots);
      console.log(couponTransaction);
      const ref = this._afs.doc(`adminNotification/${payload.id}`).ref;
      batch.set(ref, payload);
      selectedSlots.forEach((slot) => {
        if (slot.new) {
          slot.referId = slot.id;
          slot.id = this._afs.createId();
          delete slot.new;
        }
        console.log(slot);
        const ref = this._afs.doc(`slotsTransaction/${slot.id}`).ref;
        batch.set(ref, slot);
      });

      booking.id = this._afs.createId();
      if (couponTransaction) {
        couponTransaction.id = this._afs.createId();
        batch.set(
          this._afs.doc(`couponTransaction/${couponTransaction.id}`).ref,
          couponTransaction
        );
        batch.update(
          this._afs.doc(`coupon/${couponTransaction.couponId}`).ref,
          {
            couponUsed: firestore.FieldValue.increment(1),
          }
        );
      }
      booking.id = orderId;
      batch.set(this._afs.doc(`bookingTransaction/${booking.id}`).ref, booking);
      await batch.commit();
      this._toast.openSnackBar('Booking has been confirmed Successfully');
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  getAllCategory(): Observable<Array<any>> {
    return this._afs
      .collection<any>('category', (ref) => ref.orderBy('name'))
      .valueChanges();
  }

  getAllStaticSports(): Observable<Array<Sport>> {
    return this._afs
      .collection<Sport>('sports', (ref) => ref.where('isStatic', '==', true))
      .valueChanges();
  }

  getMinorsByEmail(email): Observable<Array<User>> {
    return this._afs
      .collection<User>('user', (ref) =>
        ref.where('email', '==', email).where('isMinor', '==', 'yes')
      )
      .valueChanges();
  }
}
