import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
   name: 'extension'
})
export class ExtensionPipe implements PipeTransform {
   transform(url: string): string {
      const urlparts = url.split('?');
      console.log(urlparts);
      const beforequestion = urlparts[0];
      console.log(beforequestion);
      const segments = beforequestion.split('/');
      console.log(segments);
      const imageName = segments[segments.length - 1];
      console.log(imageName);
      const extension = imageName.split('.')[1];
      console.log(extension);
      return extension;
   }
}
