import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import * as squareConnect from 'square-connect';
import {} from 'square-connect';
import { EventsService } from 'src/app/modules/home/services/events.service';
import { ToastService } from 'src/app/core/service/toast/toast.service';
//import { ApiError, Client, Environment } from 'square';
import { environment } from '../../../environments/environment';
import { nanoid } from "nanoid";

declare var SqPaymentForm: any; //magic to allow us to access the SquarePaymentForm lib
var nonces;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class SharedPaymentComponent implements OnInit {
  nounce;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: EventsService,
    private dialogRef: MatDialogRef<SharedPaymentComponent>,
    private toast: ToastService,
    private spinner: NgxSpinnerService
  ) {}
  paymentForm; //this is our payment form object
  loading = true;
  appId = 'sandbox-sq0idb-IsvDhB0xNW5duDIlZWuOQA';
  locationId = '246E837MAM5Y3';
  card = null;

  async ngOnInit() {
    // this.dialogRef.close(true);
    const accessToken =
      'EAAAEDrCrA0Un_aWNKzSfW3QTCZEDP3PFvjkl9tHQu4_NAeIyPxTMC99Y6E5kKvR';
    // Configure OAuth2 access token for authorization: oauth2
    const defaultClient = squareConnect.ApiClient.instance;
    const oauth2 = defaultClient.authentications['oauth2'];
    console.log(oauth2);
    oauth2.accessToken = accessToken;
    defaultClient.basePath = 'https://connect.squareup.com';

    const current = this;
    this.paymentForm = new SqPaymentForm({
      // Initialize the payment form elements
      // TODO: Replace with your sandbox application ID
      applicationId: 'sq0idp-Yrw946umAlvInRoEL25nGg',
      inputClass: 'sq-input',
      autoBuild: true,
      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontSize: '16px',
          lineHeight: '24px',
          padding: '16px',
          placeholderColor: '#a0a0a0',
          backgroundColor: 'transparent'
        }
      ],
      // Initialize the credit card placeholders
      cardNumber: {
        elementId: 'sq-card-number',
        placeholder: 'Card Number'
      },
      cvv: {
        elementId: 'sq-cvv',
        placeholder: 'CVV'
      },
      expirationDate: {
        elementId: 'sq-expiration-date',
        placeholder: 'MM/YY'
      },
      postalCode: {
        elementId: 'sq-postal-code',
        placeholder: 'Postal'
      },
      // SqPaymentForm callback functions
      callbacks: {
        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: function(errors, nonce, cardData) {
          if (errors) {
            console.log(errors);
            console.log(nonce);
            console.log(cardData);
            // Log errors from nonce generation to the browser developer console.
            console.error('Encountered errors:');
            errors.forEach(function(error) {
              console.error('  ' + error.message);
            });
            current.toast.openSnackBar('Enter Valid Fields');
            return;
          }
          nonces = nonce;

          console.log(nonces);
          // alert(`The generated nonce is:\n${nonce}`);
          current.payment();
          // Uncomment the following block to
          // 1. assign the nonce to a form field and
          // 2. post the form to the payment processing handler

          // document.getElementById("card-nonce").value = nonce;
          // (<HTMLButtonElement>document.getElementById('nonce-form')).click();
          //TODO: Replace alert with code in step 2.1
        },
        paymentFormLoaded: function() {
          current.loading = false;
          console.log('loaded');
          /* HANDLE AS DESIRED */
        }
      }
    });
    this.paymentForm.build();
    //@ts-ignore
    if (!window.Square) {
      throw new Error('Square.js failed to load properly');
    }

    let payments;
    try {
      //@ts-ignore
      payments = window.Square.payments(this.appId, this.locationId);
    } catch {
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      statusContainer.className = 'missing-credentials';
      statusContainer.style.visibility = 'visible';
      return;
    }

    
    async function initializeCard(payments) {
      const card = await payments.card();
      await card.attach('#card-container');

      return card;
    }


    try {
      this.card = await initializeCard(payments);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }

  }

  async payment() {
    console.log(nonces);
    this.spinner.show();
    const id = await this.service.paymentGateway(this.data);
    const res = await this.service.bookSlot(nonces, id);
    this.spinner.hide();
    console.log(res);
    if (res && res.status) {
      this.dialogRef.close(true);
    } else {
      this.toast.openSnackBar(
        'Unable To Process Payment PLease Try Again Later'
      );
    }
    console.log(res);
  }
  async cancel() {
    this.dialogRef.close();
  }

  async accessPayment() {
    const idempotency_key = 'sdfg';
    console.log(idempotency_key);

    // Charge the customer's card
    const payments_api = new squareConnect.PaymentsApi();
    const request_body = {
      source_id: nonces,
      amount_money: {
        amount: 100, // $1.00 charge
        currency: 'USD'
      },
      idempotency_key: idempotency_key
    };

    try {
      const response = await payments_api.createPayment(request_body);
      console.log(response);
      // res.status(200).json({
      //   'title': 'Payment Successful',
      //   'result': response
      // });
    } catch (error) {
      console.log(error);
      // res.status(500).json({
      //   'title': 'Payment Failure',
      //   'result': error.response.text
      // });
    }
  }
  onGetCardNonce(event) {
    console.log(event);
    // Don't submit the form until SqPaymentForm returns with a nonce
    event.preventDefault();
    // Request a nonce from the SqPaymentForm object
    this.paymentForm.requestCardNonce();
  }

  // async createPayment(token) {
  //   const body = JSON.stringify({
  //     locationId:this.locationId,
  //     sourceId: token,
  //   });

  //   const paymentResponse = await fetch('/payment', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body,
  //   });

  //   if (paymentResponse.ok) {
  //     return paymentResponse.json();
  //   }

  //   const errorBody = await paymentResponse.text();
  //   throw new Error(errorBody);
  // }

  async createPayment(token) {
    const payload = {
          locationId:this.locationId,
           sourceId: token,
         };

    // We validate the payload for specific fields. You may disable this feature
    // if you would prefer to handle payload validation on your own.
    
   // await retry(async (bail, attempt) => {
      try {
       
        const idempotencyKey = nanoid();
        const payment = {
          idempotencyKey,
          locationId: payload.locationId,
          sourceId: payload.sourceId,
          // While it's tempting to pass this data from the client
          // Doing so allows bad actor to modify these values
          // Instead, leverage Orders to create an order on the server
          // and pass the Order ID to createPayment rather than raw amounts
          // See Orders documentation: https://developer.squareup.com/docs/orders-api/what-it-does
          amountMoney: {
            // the expected amount is in cents, meaning this is $1.00.
            amount: this.data.totalAmount*100,
            // If you are a non-US account, you must change the currency to match the country in which
            // you are accepting the payment.
            currency: 'USD',
          },
        };
  
      
  
        // VerificationDetails is part of Secure Card Authentication.
        // This part of the payload is highly recommended (and required for some countries)
        // for 'unauthenticated' payment methods like Cards.
        // const square = new Client({
        //   environment: environment.production ? Environment.Production : Environment.Sandbox,
        //   accessToken: environment.SQUARE_ACCESS_TOKEN,
        // });

        // const { result, statusCode } = await square.paymentsApi.createPayment(
        //   //@ts-ignore
        //   payment
        // );
  
       // logger.info('Payment succeeded!', { result, statusCode });
  
        // send(res, statusCode, {
        //   success: true,
        //   payment: {
        //     id: result.payment.id,
        //     status: result.payment.status,
        //     receiptUrl: result.payment.receiptUrl,
        //     orderId: result.payment.orderId,
        //   },
        // });
      } catch (ex) {
        if (ex) {
          // likely an error in the request. don't retry
          // logger.error(ex.errors);
          // bail(ex);
        } else {
          // IDEA: send to error reporting service
         // logger.error(`Error creating payment on attempt ${attempt}: ${ex}`);
          throw ex; // to attempt retry
        }
      }
  }


  async tokenize(paymentMethod) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(
          tokenResult.errors
        )}`;
      }

      throw new Error(errorMessage);
    }
  }

 displayPaymentResults(status) {
    const statusContainer = document.getElementById(
      'payment-status-container'
    );
    if (status === 'SUCCESS') {
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
    }

    statusContainer.style.visibility = 'visible';
  }


  async handlePaymentMethodSubmission(event) {
    event.preventDefault();

    try {
      // disable the submit button as we await tokenization and make a payment request.
     // cardButton.disabled = true;
    // debugger;
      const token = await this.tokenize(this.card);
      const paymentResults = await this.createPayment(token);
      this.displayPaymentResults('SUCCESS');

      console.debug('Payment Success', paymentResults);
    } catch (e) {
      //cardButton.disabled = false;
      this.displayPaymentResults('FAILURE');
      console.error(e.message);
    }
  }


}

