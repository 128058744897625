import { AngularFirestore } from '@angular/fire/firestore';
import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatTableDataSource,
  MatDialog,
} from '@angular/material';
import { SignInComponent } from 'src/app/modules/dashboard/components/sign-in/sign-in.component';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { UsersService } from 'src/app/modules/dashboard/services/users.service';
import { AuthService } from 'src/app/core/auth.service';
import { SecuredLsService } from 'src/app/core/secured-ls.service';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/core/service/toast/toast.service';
import { EventsService } from 'src/app/modules/home/services/events.service';
import { isEqual, setHours, setMinutes, startOfDay } from 'date-fns';
import { User } from 'src/app/modules/dashboard/models/user';
import { LoginSignupComponent } from '../login-signup/login-signup.component';
import { Bookings, Sport } from 'src/app/modules/home/models/events';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent implements OnInit {
  allSportsData: Array<Sport>;
  activeSports: Array<Sport>;
  slots: any = [];
  allSlots: any = [];
  userDetail: User;
  minors: Array<User>;
  selected;
  checkIns = [];
  selecedCheckIn = [];
  isEditing = false;
  bookings = [];
  todaysDate = new Date();
  checkInForm = this.fb.group({
    id: [this.afs.createId()],
    userName: [null, Validators.required],
    userId: [null, Validators.required],
    email: [null, Validators.required],
    sportSelected: [null, Validators.required],
    slot: [null, Validators.required],
    minors: [null],
  });
  allBookings = [];
  constructor(
    private dialogRef: MatDialogRef<CheckInComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private fb: FormBuilder,
    private afs: AngularFirestore,
    private authService: AuthService,
    private toast: ToastService,
    private eventService: EventsService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.eventService.getAllAvailableSports().subscribe((sports: any) => {
      this.allSportsData = sports;
    });

    this.eventService.getAllSports().subscribe((data) => {
      this.activeSports = data;
    });

    this.authService.profile.subscribe((data) => {
      console.log(data);
      if (data) {
        this.userDetail = data;
        this.userId.setValue(data.id);
        this.userName.setValue(
          this.userDetail.name && this.userDetail.name !== 'undefined undefined'
            ? this.userDetail.name
            : `${this.userDetail.firstName} ${this.userDetail.lastName}`
        );
        this.email.setValue(data.email);
        this.getMinors();
      } else {
        this.userDetail = data;
      }
    });

    this.eventService.getAllBookedSlotPerDay().subscribe((data) => {
      console.log(data);
      this.allBookings = data;
      this.bookings = data;

      this.bookings.forEach((booking) => {
        booking.gameSelected = Object.values(booking.bookedSlot).map(
          (bookedSlot: any) => bookedSlot.sport
        );
      });
    });

    // Listeners
    this.sportSelectionChange();
  }

  getUserCheckInDetails(sportSelected: string) {
    this.eventService
      .getUserCheckInByDateAndSport(sportSelected, this.userId.value)
      .subscribe((data) => {
        console.log(data);
        if (data && data.length > 0) {
          this.slot.setValue(data[0].slot);
          this.minor.setValue(data[0].minors);
          this.id.setValue(data[0].id);
        }
      });
  }

  sportSelectionChange() {
    this.sportSelected.valueChanges.subscribe((data) => {
      this.slot.setValue(null);
      this.getUserCheckInDetails(data);
      this.bookings = this.allBookings.filter((slot) => {
        console.log(slot.gameSelected.includes(data));
        return slot.gameSelected.includes(data);
      });
    });
  }

  sportsData(sportsData: any) {
    throw new Error('Method not implemented.');
  }

  login() {
    this.dialog.open(LoginSignupComponent, {
      width: '60%',
      panelClass: 'custom-model',
    });
  }

  dialogClose() {
    this.dialogRef.close();
  }

  getMinors() {
    this.eventService.getMinorsByEmail(this.email.value).subscribe((data) => {
      console.log(data);
      this.minors = data;
    });
  }

  /* --------------------------- To Create DataBase --------------------------- */
  cartTable(selectedSlots): void {
    console.log(selectedSlots);
    const event = selectedSlots;
    if (!!selectedSlots) {
      const slotArray = [];
      selectedSlots = selectedSlots.bookedSlot;
      Object.keys(selectedSlots).forEach((data) => {
        const date = selectedSlots[data];
        Object.keys(date.slot).forEach((slot) => {
          date.slot[slot].forEach((time) => {
            slotArray.push(
              this.formData(
                time,
                event,
                data,
                date,
                slot,
                date.slot[slot].length,
                selectedSlots
              )
            );
          });
        });
      });
      this.allSlots = this.allSlots.concat(slotArray);
    }
  }

  formData(slot, event, data, date, category, length, bookingDetail) {
    console.log(event);
    return {
      name: event.name,
      email: event.email,
      slotId: data,
      date:
        date.date instanceof Date
          ? startOfDay(date.date)
          : startOfDay(date.date.toDate()),
      slot,
      subCategory: category,
      categoryId: date.categoryId,
      game: date.sport,
      gameName: this.allSportsData.filter(
        (sport) => sport.id === date.sport
      )[0],
      bookedOn: event.date instanceof Date ? event.date : event.date.toDate(),
      price: date.price / length,
      cancelledSlots: date.cancelledSlot ? date.cancelledSlot : [],
      checkIns: date.checkIns ? date.checkIns : [],
      checkIn: event.checkIn
        ? event.checkIn.filter(
            (check) =>
              check.game === date.sport &&
              check.subCategory === category &&
              check.slot === slot &&
              this.userId.value === check.userId
          )
        : [],
      id: date.id,
      bookingId: event.id,
    };
  }

  async checkIn() {
    if (this.checkInForm.invalid) {
      this.toast.openSnackBar('Enter Valid Form');
      this.checkInForm.markAllAsTouched();
      return;
    }

    const checkIn = this.checkInForm.value;
    checkIn.date = startOfDay(new Date());
    console.log(checkIn);
    await this.eventService.multipleCheckIn(checkIn);
  }

  editSlot(slot) {
    this.isEditing = true;
    console.log(slot);
    this.slots = this.allSlots.filter(
      (check) =>
        check.game === slot.game &&
        check.subCategory === slot.subCategory &&
        check.slot === slot.slot &&
        this.userId.value === slot.userId
    );
    this.sportSelected.setValue(slot.game);
    this.slot.setValue(
      JSON.stringify({
        game: slot.game,
        gameName: slot.gameName,
        slot: slot.slot,
        subCategory: slot.subCategory,
        bookingId: slot.bookingId,
      })
    );
    this.minor.setValue(slot.minors);

    console.log(this.slot.value);
  }

  // Getters

  get sportSelected(): AbstractControl {
    return this.checkInForm.get('sportSelected');
  }

  get id(): AbstractControl {
    return this.checkInForm.get('id');
  }

  get email(): AbstractControl {
    return this.checkInForm.get('email');
  }
  get userId(): AbstractControl {
    return this.checkInForm.get('userId');
  }
  get userName(): AbstractControl {
    return this.checkInForm.get('userName');
  }

  get slot(): AbstractControl {
    return this.checkInForm.get('slot');
  }

  get minor() {
    return this.checkInForm.get('minors');
  }

  getStart(start_end: string) {
    let startendArray = start_end.split('_');
    let start = startendArray[0];
    let h = start.substring(0, 2);
    let s = start.substring(2);
    return setMinutes(setHours(new Date(), parseInt(h)), parseInt(s));
  }
  getEnd(start_end: string) {
    let startendArray = start_end.split('_');
    let end = startendArray[1];
    let h = end.substring(0, 2);
    let s = end.substring(2);
    return setMinutes(setHours(new Date(), parseInt(h)), parseInt(s));
  }
}
