import { Sport } from './../../dashboard/models/sport';
import { ToastService } from 'src/app/core/service/toast/toast.service';
import { Observable } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Events } from '../model/event';
import { map } from 'rxjs/operators';
import { Sale } from '../model/sales';

@Injectable({
  providedIn: 'root'
})
export class SportService {
  constructor(private afs: AngularFirestore, private toast: ToastService) { }

  /* ------------------------- Get All Upcoming Events ------------------------ */
  getAllUpcomingEvents(): Observable<Array<Events>> {
    return this.afs
      .collection<Events>('events', ref =>
        ref.where('eventStartDate', '>=', new Date())
      )
      .valueChanges();
  }

  getHomeEvents(): Observable<Array<Events>> {
    return this.afs
      .collection<Events>('events', ref => ref.limit(4))
      .valueChanges();
  }
  getOnGoingEvents(): Observable<Array<Events>> {
    return this.afs
      .collection<Events>('events', ref =>
        ref.where('eventEndDate', '>=', new Date())
      )
      .valueChanges()
      .pipe(
        map(data => {
          data = data.filter(
            event => event.eventStartDate.toDate() <= new Date()
          );
          return data;
        })
      );
  }
  getPastEvents(): Observable<Array<Events>> {
    return this.afs
      .collection<Events>('events', ref =>
        ref.where('eventEndDate', '<=', new Date())
      )
      .valueChanges();
  }
  /* ------------------------- Get Event By Id ------------------------ */
  getEventById(id: string): Observable<Events> {
    return this.afs.doc<Events>(`events/${id}`).valueChanges();
  }

  getHomeDetail(): Observable<any> {
    return this.afs.doc(`home/home`).valueChanges();
  }

  /* --------------------- Getting All Slots For An Event --------------------- */
  getRegisteredEvent(id: string): Observable<Array<any>> {
    return this.afs
      .collection(`bookingTransaction`, ref =>
        ref.where('eventId', '==', id).where('isTeam', '==', false)
      )
      .valueChanges();
  }
  /* --------------------- Getting All Team Slots For An Event --------------------- */
  getRegisteredTeamEvent(id: string): Observable<Array<any>> {
    return this.afs
      .collection(`bookingTransaction`, ref =>
        ref.where('eventId', '==', id).where('isTeam', '==', true)
      )
      .valueChanges();
  }
  /* --------------------- Getting All Slots For An Event --------------------- */
  getEventTransaction(id: string): Observable<Array<any>> {
    return this.afs
      .collection(`slotsTransaction`, ref =>
        ref.where('sportId', '==', id).orderBy('date', 'asc')
      )
      .valueChanges();
  }
  /* --------------------- Getting Sport Details By Id --------------------- */
  getSportsDetail(id: string): Observable<Sport> {
    return this.afs.doc<Sport>(`sports/${id}`).valueChanges();
  }

  /* -------------------------------- Get Sales ------------------------------- */
  getSales(): Observable<Array<Sale>> {
    return this.afs.collection<Sale>('sales').valueChanges();
  }
  /* -------------------------------- Get Sale Details ------------------------------- */
  getSaleById(id: string): Observable<Sale> {
    return this.afs.doc<Sale>(`sales/${id}`).valueChanges();
  }
  /* --------------------------- OrderId Transaction -------------------------- */
  async getOrderId(registerEvent: any) {
    let orderBillNo;
    let orderRef = this.afs.doc(`orders/orderId`).ref;
    this.afs.firestore
      .runTransaction(transaction => {
        return transaction.get(orderRef).then(async orderId => {
          if (!orderId.exists) {
            throw new Error('Document does not exist!');
          }
          const bookDocId = parseInt(orderId.data().id.slice(1));
          const id = bookDocId + 1;
          orderBillNo = `B${id}`;
          transaction.update(orderRef, { id: orderBillNo });
        });
      })
      .then(async data => {
        console.log(data);
        await this.registerEvent(registerEvent, orderBillNo);
        console.log('transaction complete');
      });
  }

  /* ----------------------------- Register Event ----------------------------- */
  async registerEvent(eventRegister: any, id: string): Promise<boolean> {
    try {
      eventRegister.id = id;
      const payload = {
        id: this.afs.createId(),
        action: 'booking',
        message: 'Events Has Been Booked',
        createdOn: new Date(),
        isRead: false
      };
      const batch = this.afs.firestore.batch();
      const ref = this.afs.doc(`adminNotification/${payload.id}`).ref;
      batch.set(ref, payload);
      batch.set(
        this.afs.doc(`bookingTransaction/${eventRegister.id}`).ref,
        eventRegister
      );
      await batch.commit();
      this.toast.openSnackBar('Event Has Been Registered Successfully');
      return true;
    } catch (error) {
      this.toast.openSnackBar('Event Registration Failed');
      console.log(error);
      return false;
    }
  }
}
