import { Injectable } from '@angular/core';
import * as SecureLS from 'secure-ls';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecuredLsService {
  /* --------------------------------- Fields --------------------------------- */
  encodingConfig = {
    encodingType: 'aes',
    isCompression: true,
    encryptionSecret: environment.lsKey,
    encryptionNamespace: 'CUBA'
  };

  ls: SecureLS;
  /* ------------------------------- Constructor ------------------------------ */
  constructor() {
    this.ls = new SecureLS(this.encodingConfig);
  }
  /* ----------------------------- Custom Methods ----------------------------- */

  // sets the value in local storage with encryption
  set(key: string, value: any): void {
    this.ls.set(key, value);
  }

  // gets the value from local storage and decrypts
  get(key: string): any {
    return this.ls.get(key);
  }

  // gets all the saved keys in local storage
  getAllKeys(): Array<string> {
    return this.ls.getAllKeys();
  }

  // removes all the saved keys in local storage
  removeAllKeys(): void {
    this.ls.removeAll();
  }

  // removes specified key from local storage
  removeKeys(key: string): void {
    this.ls.remove(key);
  }
}
