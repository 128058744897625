import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EventsService } from './../../services/events.service';
import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { share } from 'rxjs/operators';
export interface BrandArray {
  image: string;
}
@Component({
  selector: 'ads-advertisement',
  templateUrl: './ads-advertisement.component.html',
  styleUrls: ['./ads-advertisement.component.scss']
})
export class AdsAdvertisementComponent implements OnInit {
  load = false;
  public disabled: boolean = false;

  public slides = [
    'First slide',
    'Second slide',
  ];

  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    loop: true,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  sponsorsList: Array<any>;

  brandsArray: BrandArray[] = [
    { image: '../../assets/images/banners/1.jpg' },
    { image: '../../assets/images/widget-1.jpg' },
    { image: '../../assets/images/banners/33.jpg' },
    { image: '../../assets/images/widget-2.jpg' },
    { image: '../../assets/images/banners/55.jpg' },
    { image: '../../assets/images/widget-3.jpg' },
  ];
  constructor(private service: EventsService, private router: Router) { }

  ngOnInit() {
    this.service.getAllSponsors().subscribe(data => {
      console.log(data);
      this.sponsorsList =data;
      this.load = true;
    });
  }

  navigate(link){
  console.log(link);
  window.open(link, "_blank");
  }

}
