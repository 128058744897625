import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'src/app/modules/dashboard/services/users.service';
import { AuthService } from 'src/app/core/auth.service';
import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormGroup,
  FormBuilder,
  FormGroupDirective
} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactUsForm: FormGroup;
  submitted: boolean;
  profile;
  contactUs: any;
  constructor(
    private _fb: FormBuilder,
    private _usersService: UsersService,
    private service: AuthService,
    private acr: ActivatedRoute
  ) {}

  ngOnInit() {
    const type = this.acr.snapshot.paramMap.get('type');
    if (type === 'contact') {
      this.scrollToBottom();
    }
    this._usersService.getCommonThings('hours').subscribe(data => {
      this.contactUs = !!data ? data.text : data;
    });
    this.contactUsForm = this._fb.group({
      id: [],
      name: ['', [Validators.required]],
      userId: [],
      enquiry: [null, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      createdTime: [new Date()],
      status: ['pending']
    });
    this.service.profile.subscribe(data => {
      if (!!data) {
        console.log(data);
        this.profile = data;
        this.name.setValue(`${data.firstName} ${data.lastName}`);
        this.email.setValue(`${data.email}`);
        this.contactUsForm.get('userId').setValue(data.id);
      }
    });
  }

  scrollToBottom() {
    console.log(document.body.scrollHeight);
    window.scrollTo({
      left: 0,
      top: document.body.scrollHeight - 300,
      behavior: 'smooth'
    });

    // document.body.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'end',
    //   inline: 'nearest'
    // });
  }
  async contactSubmit(formDirective: FormGroupDirective): Promise<void> {
    this.submitted = true;
    console.log(this.contactUsForm);

    if (this.contactUsForm.valid) {
      const contactPayload = this.contactUsForm.value;
      contactPayload.createdTime = new Date();
      this._usersService
        .newGeneralEnquiry(this.contactUsForm.value)
        .then(data => {
          this.contactUsForm.reset();
          formDirective.resetForm();
        });
    } else {
      this.contactUsForm.markAllAsTouched();
    }
  }
  get name() {
    return this.contactUsForm.get('name');
  }
  get email() {
    return this.contactUsForm.get('email');
  }
  get subject() {
    return this.contactUsForm.get('subject');
  }
}
