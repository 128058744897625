import { take } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { PaymentStatusComponent } from './../../components/payment-status/payment-status.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SubCategory, Category } from './../../../dashboard/models/sport';
import { AuthService } from 'src/app/core/auth.service';
import { SecuredLsService } from './../../../../core/secured-ls.service';
import { Sport } from './../../models/events';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import * as _ from 'lodash';
import {
  startOfMonth,
  parse,
  isEqual,
  getDay,
  setMinutes,
  setHours,
} from 'date-fns';
import { ToastService } from 'src/app/core/service/toast/toast.service';
import { EventsService } from '../../services/events.service';
import {
  MatHorizontalStepper,
  MatCalendar,
  MatCalendarCellCssClasses,
  MatTableDataSource,
  MatDialog,
  MatSort,
  MatButtonToggle,
  MatButtonToggleGroup,
} from '@angular/material';
import { Coupon } from 'src/app/modules/dashboard/models/user';
import { Action } from 'rxjs/internal/scheduler/Action';
import { ExampleHeader } from '../../components/custom-header/custom-header.component';
import { LoginSignupComponent } from 'src/app/shared/login-signup/login-signup.component';
import { WaiverFormComponent } from '../../components/waiver-form/waiver-form.component';
import { differenceInHours, differenceInMinutes, startOfDay } from 'date-fns';
import { SharedPaymentComponent } from 'src/app/shared/payment/payment.component';
import * as moment from 'moment-timezone';
export interface PeriodicElement {
  day: string;
  sports: string;
  slots: string;
  price: string;
  action: string;
}
let fastFilling = [];
let bookedDates = [];
let selectedDates = [];
let transactionArray = [];
let masterData = [];
let emptyDates = [];
const globalDays = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};
@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss'],
})
export class SlotBookingComponent implements OnInit {
  displayedColumns: string[] = ['date', 'sports', 'slots', 'price', 'action'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource([]);
  /* --------------------------------- Fields --------------------------------- */
  showCoupon = false;
  loadCalender = false;
  invalidCoupon = '';
  slotBookForm: FormGroup;
  slotTimingsForm: FormGroup;
  bookingUserForm: FormGroup;
  checked = false;
  indeterminate = false;
  labelPosition = 'after';
  disabled = false;
  myDate = new Date();
  minDate = new Date();
  submitted = false;
  sportsData: Array<any>;
  allSportsData: Array<any> = [];
  selectedDays = [];
  slotsData: Array<any> = [];
  selectedSlots: any = {};
  sportSubscription: Subscription;
  allSlotsList: Array<any>;
  priceMaster = {};
  days = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
  };
  objectKeys = Object.keys;
  planType;
  loading = false;
  @ViewChild(MatHorizontalStepper, { static: false })
  public step: MatHorizontalStepper;
  @ViewChild(MatCalendar, { static: false }) public calender: MatCalendar<any>;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild('grp', { static: false }) group: MatButtonToggleGroup;
  @ViewChildren(MatButtonToggleGroup) groupq: QueryList<MatButtonToggleGroup>;

  couponApplied = false;
  coupon: Coupon;
  refresh = false;
  slots = {};
  couponValidity: boolean;
  homeSetting: any;
  couponList: Observable<Array<Coupon>>;
  exampleHeader;
  userDetail;
  profile;
  activeMonth;
  categoryList: any[];
  discountedPrice: any;
  above16: boolean;
  // Discounted Price Above 2 Hrs But Less Than 15 Hrs
  discountedPriceForSlotBookingAbove2Hrs = 0; //5
  // Discounted Percentage Above 15 Hrs
  discountedPercentageForSlotBookingAbove15Hrs = 0; //0.15%
  constructor(
    private _toast: ToastService,
    // @Inject(MAT_DIALOG_DATA) public data: Contests,
    // private _contestsService: ContestsService,
    private _eventsService: EventsService,
    private _fb: FormBuilder,
    private _sls: SecuredLsService,
    private _auth: AuthService,
    private dialog: MatDialog,
    private acr: ActivatedRoute,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService
  ) {}

  close() {
    this.close();
  }
  ngOnInit() {
    console.log(moment.tz(new Date(), 'america/new_york').hours());
    this.exampleHeader = ExampleHeader;
    this.couponList = this._eventsService.getSlotCoupon();
    const id = this.acr.snapshot.paramMap.get('id');
    this._eventsService.getHomeDetail().subscribe((data) => {
      console.log(data);
      this.homeSetting = data;
    });

    this.sportSubscription = this._eventsService
      .getAllSports()
      .subscribe((sports: any) => {
        this.allSportsData = _.cloneDeep(sports);
        console.log(this.allSportsData);
        if (this.userDetail && this.userDetail.isSubEnabled) {
          this.checkSportValidity(this.userDetail);
        } else {
          this.sportsData = sports.filter(
            (data) => !data.subs4HrOnly && !data.subs8HrOnly
          );
          console.log(this.sportsData);
        }
        if (!!id) {
          this.game.setValue(id);
          this.sportChanged();
        }
      });
    this.slotBookForm = this._fb.group({
      id: [''],
      date: [null, Validators.required],
      viewType: ['Lanes'],
      type: ['booking'],
      game: ['', Validators.required],
      slots: [[]],
      bookedSlot: [null, Validators.required],
    });
    this.slotTimingsForm = this._fb.group({
      totalAmount: [0, Validators.required],
      billPrice: [null, Validators.required],
      createdTime: [],
      slots: [[]],
      couponCode: [],
    });

    this._eventsService
      .getAllCategory()
      .subscribe((sports) => (this.categoryList = sports));
    this.bookingUserForm = this._fb.group({
      userId: [],
      name: ['aakash', Validators.required],
      email: ['aakashgupta@gmail.com', Validators.required],
      phoneNumber: ['', Validators.required],
    });
    this._eventsService.monthSelected.subscribe((month) => {
      this.activeMonth = month;
      if (this.game.value) {
        this.getAvailabilityForMonth();
      }
      console.log(month);
    });
    this._auth.profile.subscribe((data) => {
      if (data) {
        this.userDetail = data;
        console.log(data);
        if (data.isSubEnabled) {
          this.checkSportValidity(data);
        }
        this.profile = data;
        this.bookingUserForm.get('userId').setValue(data.id);
        this.name.setValue(`${data.firstName} ${data.lastName}`);
        this.email.setValue(`${data.email}`);
      } else {
        this.profile = null;
      }
    });
  }

  checkSportValidity(data) {
    if (data.isSubEnabled) {
      const plan = data.planType === '4Hour' ? 'subs4HrOnly' : 'subs8HrOnly';
      this.planType = plan;
      console.log(plan);
      console.log(this.allSportsData);
      this.sportsData = this.allSportsData.filter(
        (data) => data[plan] || (!data.subs4HrOnly && !data.subs8HrOnly)
      );
    }
  }

  checkExpiredSlots(slot, isBooked: boolean, subName) {
    console.log(isBooked, slot, this.date.value, subName);
    if (isBooked) {
      return true;
    }

    const currentMoment = moment.tz(new Date(), 'america/new_york');
    const selectedMoment = moment.tz(this.date.value, 'america/new_york');

    if (
      !(
        selectedMoment.date() === currentMoment.date() &&
        selectedMoment.month() === currentMoment.month() &&
        selectedMoment.year() === currentMoment.year()
      )
    ) {
      if (
        selectedMoment.date() < currentMoment.date() &&
        selectedMoment.month() === currentMoment.month() &&
        selectedMoment.year() === currentMoment.year()
      ) {
        return true;
      }
      return false;
    }
    const currentHour = currentMoment.hours();
    const currentMinute = currentMoment.minutes();
    const startTime = this.getTime(slot, 0);
    // return false;
    if (startTime.hour > currentHour) {
      return false;
    } else if (startTime.hour === currentHour) {
      if (startTime.minutes > currentMinute) {
        return false;
      }
      return true;
    }
    return true;
  }
  /* ------------------ Gets Filled Slots  For Selected Month ----------------- */

  getAvailabilityForMonth() {
    console.log(this.activeMonth);
    this.loadCalender = true;
    const monthSelected = this.datePipe.transform(
      startOfMonth(this.activeMonth),
      'dd-MM-yyyy'
    );
    this._eventsService
      .getAvailability(monthSelected, this.game.value)
      .subscribe((data) => {
        console.log(data);
        let transaction: Array<any> = data.transactions;
        const masters = data.master;
        const tempTimeSlots = [];
        transaction.forEach((slot) => {
          if (slot.sportId !== this.game.value) {
            const checkDate = transaction.filter(
              (slotDate) =>
                this.checkForDate(slotDate.date) ===
                  this.checkForDate(slot.date) &&
                this.game.value === slotDate.sportId
            );
            console.log(checkDate[0]);
            if (checkDate.length === 0) {
              const tempTimeSlot = {
                day: this.days[
                  getDay(
                    _.isFunction(slot.date.toDate)
                      ? slot.date.toDate()
                      : slot.date
                  )
                ],
                date: _.isFunction(slot.date.toDate)
                  ? slot.date.toDate()
                  : slot.date,
                slot: {},
                sportId: this.game.value,
                status: true,
              };
              console.log(tempTimeSlot);
              tempTimeSlots.push(tempTimeSlot);
            }
          }
          if (_.isFunction(slot.date.toDate)) {
            slot.date = slot.date.toDate();
            console.log(slot.date);
          }
        });
        console.log(tempTimeSlots);
        transaction = [...transaction, ...tempTimeSlots];
        console.log(transaction);
        transaction = transaction.filter(
          (slotData) => slotData.sportId === this.game.value
        );
        const datesSlot = _.groupBy(transaction, 'date');
        const master = _.groupBy(masters, 'day');
        masterData = [];
        Object.keys(master).forEach((day) => {
          let isAvailable = 0;
          console.log(master[day]);
          master[day].forEach((slots) => {
            isAvailable = Object.keys(slots.slot).length;
            if (isAvailable > 0) {
              return;
            }
          });
          if (isAvailable > 0) {
            masterData.push(day);
          }
        });
        fastFilling = [];
        bookedDates = [];
        transactionArray = [];
        emptyDates = [];
        this.loadCalender = false;
        this.checkAvailability(datesSlot);
        if (this.calender) this.calender.updateTodaysDate();

        this.onSelect(startOfMonth(this.activeMonth));
      });
  }

  checkForDate(date) {
    return _.isFunction(date.toDate) ? date.toDate() : date;
  }

  /* -------------------------- Get SubCategory Name -------------------------- */
  getSubCategoryName(id, categoryId) {
    if (categoryId && this.categoryList) {
      const category = this.categoryList.filter(
        (sport) => sport.id === categoryId
      )[0];
      const subCategory = category.types.filter((sport) => sport.id === id);
      if (subCategory.length === 0) {
        return 'id';
      } else {
        return subCategory[0].name;
      }
    }
    return id;
  }

  /* -------------------------- Applying Dynamic Css For Calender -------------------------- */
  applyCss(d: Date): MatCalendarCellCssClasses {
    const data = fastFilling.filter((date) => isEqual(d, startOfDay(date)));
    const available = transactionArray.filter((date) =>
      isEqual(d, startOfDay(date))
    );
    const master = masterData.filter(
      (date) => globalDays[getDay(d).toString()] === date
    );
    const empty = emptyDates.filter((date) => isEqual(d, startOfDay(date)));
    const isSelected =
      selectedDates.length > 0
        ? selectedDates.filter((date) => isEqual(date.date, d))
        : [];
    if (d < new Date()) {
      return null;
    }
    // console.log(data);
    if (data.length > 0) {
      console.log('apply');
      return 'fast-filling';
    } else if (available.length > 0 && isSelected.length === 0) {
      console.log(available);
      console.log(
        'available-------------------------------------------------------'
      );
      return 'available';
    } else if (
      master.length > 0 &&
      empty.length === 0 &&
      isSelected.length === 0
    ) {
      return 'available';
    }
    if (isSelected.length > 0) {
      console.log('apply-as');
      return 'date-selected';
    }
  }

  /* ------------------------- Disabling Filled Dates ------------------------- */
  disable(d: Date): boolean {
    const data = bookedDates.filter((date) => isEqual(d, startOfDay(date)));
    if (data.length > 0 || d < startOfDay(new Date())) {
      console.log('apply');
      return false;
    } else {
      return true;
    }
  }

  /* ----------------------- Checking For Availability ---------------------- */
  checkAvailability(slots) {
    console.log(slots);
    Object.keys(slots).forEach((data) => {
      let isBooked = true;
      let isFastFilling = false;
      let isEmpty = true;
      slots[data].forEach((day) => {
        if (this.objectKeys(day.slot).length > 0) {
          isEmpty = false;
          Object.keys(day.slot).forEach((slot) => {
            if (day.slot[slot].space === 1) {
              isBooked = false;
            } else {
              isFastFilling = true;
            }
          });
        }
      });
      if (isBooked && isFastFilling) {
        bookedDates.push(parse(data));
      } else if (isFastFilling) {
        fastFilling.push(parse(data));
      } else if (!isEmpty) {
        transactionArray.push(parse(data));
      } else if (isEmpty) {
        emptyDates.push(parse(data));
      }
      console.log(transactionArray);
      console.log(emptyDates);
    });
  }

  /* ----------------------------- Slot Selection ----------------------------- */
  selectedSlot(subCategory, event, slots) {
    const slot = _.cloneDeep(slots);
    console.log(slot);
    if (!this.slots[this.date.value]) {
      this.slots[this.date.value] = _.cloneDeep(this.allSlotsList);
    }

    if (
      /* --------------------- It checks For Removal For Slot --------------------- */

      (this.selectedSlots[`${this.date.value}_${this.game.value}`]
        ? this.selectedSlots[
            `${this.date.value}_${this.game.value}`
          ].slot.hasOwnProperty(subCategory)
        : false) &&
      event.value.length === 0
    ) {
      delete this.selectedSlots[`${this.date.value}_${this.game.value}`].slot[
        subCategory
      ];
      if (
        Object.keys(
          this.selectedSlots[`${this.date.value}_${this.game.value}`].slot
        ).length === 0
      ) {
        this.removeAllSlot(
          this.date.value,
          this.game.value,
          this.selectedSlots[`${this.date.value}_${this.game.value}`].slot
        );
      }
    } else {
      /* --------------------- Condition When Slots Are Added --------------------- */
      event.value.forEach((selected) => (slot.slot[selected].space = 0));
      this.slots[this.date.value] = this.slots[this.date.value].map((data) => {
        if (
          data.sportId === slot.sportId &&
          data.subCategoryId === subCategory
        ) {
          data = slot;
        }
        return data;
      });
      if (!this.selectedSlots[`${this.date.value}_${this.game.value}`]) {
        this.selectedSlots[`${this.date.value}_${this.game.value}`] = {
          date: this.date.value,
          sport: this.game.value,
          id: slot.id,
          month: slot.month,
          categoryId: slot.categoryId,
        };
      }
      const category =
        this.selectedSlots[`${this.date.value}_${this.game.value}`].slot;
      this.selectedSlots[`${this.date.value}_${this.game.value}`].slot =
        !!category
          ? { ...category, [subCategory]: event.value }
          : { [subCategory]: event.value };
    }

    /* --------------------- Calculating Price For Each Day --------------------- */
    let price = 0;
    Object.keys(this.selectedSlots).forEach((sub) => {
      console.log(sub);
      Object.keys(this.selectedSlots[sub].slot).forEach((subC) => {
        console.log(subC);
        if (slot && sub === `${this.date.value}_${this.game.value}`) {
          this.selectedSlots[sub].slot[subC].forEach((time) => {
            console.log(this.slots[`${this.date.value}`]);
            console.log(this.selectedSlots[sub]);
            const data = this.slots[`${this.date.value}`].filter(
              (item) =>
                item.subCategoryId === subC &&
                item.sportId === this.selectedSlots[sub].sport
            );
            console.log(data);
            // data = data[0];
            // console.log(time);
            data.forEach((item) => {
              console.log(item);
              if (item && item.slot[time]) {
                price += item.slot[time].price;
                return;
              }
            });
            // if (data && data.slot[time]) {
            //   price += data.slot[time].price;
            // };
          });
        }
      });
      this.selectedSlots[`${this.date.value}_${this.game.value}`].price = price;
      this.priceMaster[sub] = price;
    });

    this.cartTable();
  }

  /* ------------------------------- Remove Slot ------------------------------ */
  removeSlot(subCategory, date, game, slot, id) {
    /* --------- Checking For Slot Is Present And SubCategory Is Present -------- */
    if (
      this.selectedSlots[`${date}_${game}`] &&
      this.selectedSlots[`${date}_${game}`].slot.hasOwnProperty(subCategory)
    ) {
      const slots = this.selectedSlots[`${date}_${game}`].slot[subCategory];
      this.selectedSlots[`${date}_${game}`].slot[subCategory] = slots.filter(
        (data) => data !== slot
      );
      console.log(this.selectedSlots[`${date}_${game}`].slot[subCategory]);
      if (
        this.selectedSlots[`${date}_${game}`].slot[subCategory].length === 0
      ) {
        delete this.selectedSlots[`${date}_${this.game.value}`].slot[
          subCategory
        ];
      }
      console.log(this.slots);
      // Changing Slot Available  To 1 And Decreasing Amount Based ON Removal
      this.slots[date].forEach((dates) => {
        console.log(dates);
        if (dates.sportId === game && dates.subCategoryId === subCategory) {
          dates.slot[slot].space = 1;
          this.selectedSlots[`${date}_${game}`].price = Math.abs(
            this.selectedSlots[`${date}_${game}`].price - dates.slot[slot].price
          );
        }
      });
      if (
        Object.keys(this.selectedSlots[`${date}_${this.game.value}`].slot)
          .length === 0
      ) {
        console.log('removing All Slots');
        this.removeAllSlot(
          date,
          this.game.value,
          this.selectedSlots[`${date}_${this.game.value}`].slot
        );
      }
      /* ------------- Checking If Removing Date Same As Current Date ------------- */
      if (isEqual(date, this.date.value)) {
        const re = this.groupq.filter((data) => data.name === subCategory)[0];
        console.log(re.value);
        if (re.value.length === 1) {
          re.value = [];
        }
        re.change.subscribe((data) => console.log(data));
      }
    }

    this.getSelectedSlot();
    this.cartTable();
  }

  /* ----------------- Removing All Slot For A Particular Day ----------------- */
  removeAllSlot(date, game, slots) {
    delete this.selectedSlots[`${date}_${game}`];
    const dates = this.dataSource.data.filter((data) => data.date === date);
    console.log(dates);
    if (dates.length === 1) {
      this.objectKeys(dates[0].slot).forEach((key) => {
        /* ------------- Checking If Removing Date Same As Current Date ------------- */
        if (isEqual(date, this.date.value)) {
          const re = this.groupq.filter((data) => data.name === key)[0];
          console.log(re.value);
          if (re.value.length === 1) {
            re.value = [];
          }
          re.change.subscribe((data) => console.log(data));
        }
      });
      delete this.slots[date];
    } else {
      Object.keys(slots).forEach((slot) => {
        this.slots = this.slots[date].map((data) => {
          if (data.sportId === game && data.subCategoryId === slot) {
            slots[slot].forEach((time) => (data[time] = 1));
          }
          return data;
        });
      });
    }
    this.cartTable();
    this.getSelectedSlot(dates[0]);
  }
  /* --------------------------- To Create Data Table --------------------------- */
  cartTable(): void {
    let slotArray = [];
    this.selectedDays = [];
    Object.keys(this.selectedSlots).forEach((data) => {
      console.log(data);
      const date = this.selectedSlots[data];
      const timeDiff = this.getHoursForEachSlot(data);
      const dayTotalHours = timeDiff.dayTotalHours;
      console.log(dayTotalHours);
      console.log(date);
      let dayPrice;
      if (dayTotalHours >= 2) {
        console.log(date.price);
        dayPrice =
          date.price -
          dayTotalHours * this.discountedPriceForSlotBookingAbove2Hrs;
        console.log(dayPrice);
        dayPrice = dayPrice < 0 ? 0 : dayPrice;
      }
      this.selectedDays.push(startOfDay(date.date));
      slotArray.push({
        date: date.date,
        slot: date.slot,
        game: date.sport,
        categoryId: date.categoryId,
        month: date.month,
        price: dayPrice ? dayPrice : date.price,
        actualPrice: date.price,
        id: date.id,
      });
    });
    slotArray = _.orderBy(slotArray, ['date', 'asc']);
    this.dataSource = new MatTableDataSource(slotArray);
    this.dataSource.sort = this.sort;
    selectedDates = this.dataSource.data;
    console.log(slotArray);
    let price = 0;
    this.dataSource.data.forEach((data) => {
      price += data.actualPrice;
    });
    console.log(price);
    if (this.dataSource.data.length > 0) {
      this.bookedSlot.setValue(this.selectedSlots);
    } else {
      this.step.selectedIndex = 0;
      this.bookedSlot.setValue(null);
    }
    if (price <= 0) {
      price = 0;
    }
    this.totalAmount.setValue(price);
    this.billPrice.setValue(price);
    this.checkHours();

    console.log(this.totalAmount.value, this.billPrice.value);
  }

  checkHours() {
    let hour = 0;
    let minutes = 0;
    let dateHours = 0;
    Object.keys(this.selectedSlots).forEach((data) => {
      const timeDiff = this.getHoursForEachSlot(data, hour, minutes);
      hour = timeDiff.hour;
      minutes = timeDiff.minutes;
      const dayTotalHours = timeDiff.dayTotalHours;
      console.log(dayTotalHours);
      if (dayTotalHours >= 2) {
        dateHours += dayTotalHours;
      }
    });

    const hr = (minutes - hour * 60) / 60;
    const fr = hr.toString().split('.');
    const totalHours = hour + parseInt(fr[0]);
    let price = this.totalAmount.value;

    if (totalHours < 15 && dateHours >= 2) {
      price = price - dateHours * this.discountedPriceForSlotBookingAbove2Hrs;
      this.billPrice.setValue(price < 0 ? 0 : price);
    } else if (totalHours >= 15) {
      this.above16 = true;
      price = price - dateHours * this.discountedPriceForSlotBookingAbove2Hrs;
      price = price < 0 ? 0 : price;
      this.discountedPrice = _.cloneDeep(price);
      this.billPrice.setValue(
        price - price * this.discountedPercentageForSlotBookingAbove15Hrs
      );
    }
    if (totalHours < 15) {
      this.above16 = false;
      this.discountedPrice = _.cloneDeep(this.billPrice.value);
    }
  }

  getHoursForEachSlot(data, hour?, minutes?) {
    let dayHour = 0;
    let dayMinutes = 0;
    const slots = this.selectedSlots[data].slot;
    Object.keys(slots).forEach((slot) => {
      slots[slot].forEach((time) => {
        console.log(time);
        const startDate = this.getStart(time);
        const endDate = this.getEnd(time);
        if (hour !== undefined) {
          hour += differenceInHours(endDate, startDate);
          minutes += differenceInMinutes(endDate, startDate);
        }
        dayHour += differenceInHours(endDate, startDate);
        dayMinutes += differenceInMinutes(endDate, startDate);
      });
    });
    const dayHr = (dayMinutes - dayHour * 60) / 60;
    const dayFr = dayHr.toString().split('.');
    const dayTotalHours = dayHour + parseInt(dayFr[0]);
    console.log(dayTotalHours);
    return { dayTotalHours, hour, minutes };
  }

  selectedDate($event) {
    console.log($event);
    this.myDate = $event;
  }

  /* -------------------------- Sports Changed Event -------------------------- */
  sportChanged() {
    if (!!this.game.value) {
      const sports = this.sportsData.filter(
        (sport) => sport.id === this.game.value
      )[0];
      console.log(this.game.value);
      console.log(sports);
      this.viewType.setValue(
        sports && sports.category && sports.category.name
          ? sports.category.name
          : null
      );
      // this.myDate = null;
    }
    this.getAvailabilityForMonth();
    if (this.date.value) {
      this.loading = true;
      this.getSlots();
      this.getSelectedSlot();
    }
  }

  /* ---------------------------- Gets Sports  Name --------------------------- */
  getName(id): string {
    const sports = this.sportsData.filter((sport) => sport.id === id)[0];
    return sports.name;
  }

  /* ----------------------- get Slots For Selected Date ---------------------- */
  getSlots() {
    const monthSelected = this.datePipe.transform(
      startOfMonth(this.date.value),
      'dd-MM-yyyy'
    );
    this._eventsService
      .getSlotsByDay(
        this.date.value,
        this.days[this.date.value.getDay()],
        monthSelected
      )
      .subscribe((data) => {
        this.allSlotsList = data;
        console.log(data);
        data = data.map((item) => {
          item.subCategoryName = this.getSubCategoryName(
            item.subCategoryId,
            item.categoryId
          );
          return item;
        });
        console.log(data);
        data = _.orderBy(data, ['subCategoryName', 'asc']);
        this.slotsData = data.filter(
          (slot) => slot.sportId === this.game.value
        );
        console.log(this.slotsData);
        this.loading = false;
      });
  }

  /* ----------------------------- Applying Coupon ---------------------------- */
  async applyCoupon(couponSelected?: Array<Coupon>) {
    let code: Array<any>;
    this.cartTable();
    if (couponSelected) {
      code = couponSelected;
      this.couponCode.setValue(code[0].couponCode);
    } else {
      code = await this._eventsService.getCouponCode(this.couponCode.value);
    }

    // planType
    if (code.length === 0) {
      this.invalidCoupon = `Coupon code does not exist.`;
      this._toast.openSnackBar('Coupon code does not exist.');
    } else {
      if (code[0].subs4HrOnly || code[0].subs8HrOnly) {
        if (this.planType) {
          if (!code[0][this.planType]) {
            this._toast.openSnackBar('Cannot Apply Coupon');
            return;
          }
        } else {
          this._toast.openSnackBar('Cannot Apply Coupon');
          return;
        }
      }
      this.coupon = code[0];
      const coupon = code[0];
      if (
        coupon.dateRange[0].toDate() <= startOfDay(new Date()) &&
        coupon.dateRange[1].toDate() >= startOfDay(new Date())
      ) {
        let totalAmount = _.cloneDeep(this.billPrice.value);
        if (coupon.minPrice <= totalAmount) {
          console.log(this.checkUsage(coupon));
          if (await this.checkUsage(coupon)) {
            if (coupon.type === 'Discount') {
              totalAmount -= this.billPrice.value * (coupon.discounted / 100);
              console.log(totalAmount);
              this.showCoupon = false;
              if (totalAmount < 0) {
                totalAmount = 0;
              }
              this.billPrice.setValue(totalAmount.toFixed(2));
            } else {
              console.log(totalAmount - coupon.discounted);
              const price = totalAmount - coupon.discounted;
              this.showCoupon = false;

              this.billPrice.setValue(price < 0 ? 0 : price.toFixed(2));
            }
            this.invalidCoupon = null;
          } else {
            this.invalidCoupon = 'Coupon Already Used';
            this._toast.openSnackBar(`Coupon Already Used`);
          }
        } else {
          this.invalidCoupon = `Minimum Of ${coupon.minPrice}$ is Required`;
          this._toast.openSnackBar(`Minimum Of ${coupon.minPrice} is Required`);
        }
        this.couponApplied = true;
      } else {
        this.couponValidity = true;
        this.invalidCoupon = `Coupon Validity period expired`;
        this._toast.openSnackBar('Coupon Validity period expired');
      }
    }
    if (this.invalidCoupon) {
      this.cartTable();
    }
  }

  /* -------------------------- Remove Applied Coupon ------------------------- */
  removeCoupon() {
    this.coupon = null;
    this.couponCode.setValue(null);
    // this.billPrice.setValue(this.totalAmount.value);
    this.cartTable();
  }

  /* -------------------------- Checking Coupon Usage ------------------------- */
  async checkUsage(coupon: Coupon) {
    if (!!coupon.usageLimit) {
      const usage = await this._eventsService.getCouponTransactionCodeUser(
        this.couponCode.value,
        this.email.value
      );
      console.log(usage);
      console.log(coupon.usageLimit);
      console.log(coupon.usageLimit > usage.length);
      if (coupon.usageLimit > usage.length) {
        return true;
      }
      return false;
    }
    return true;
  }

  /*----------------------------- Date Selection ----------------------------- */
  onSelect(event) {
    console.log(event);
    this.slotBookForm.get('slots').setValue([]);
    this.date.setValue(startOfDay(event));
    this.selectedDate = startOfDay(event) as any;
    this.loading = true;
    this.getSlots();
    console.log(this.calender.updateTodaysDate());
    this.getSelectedSlot();
  }

  /* ------------------------ Gets Slots Based On Date ------------------------ */
  getSelectedSlot(slot?: any) {
    if (this.dataSource.data.length > 0) {
      const data = this.dataSource.data.filter(
        (slots) =>
          isEqual(slots.date, this.date.value) && slots.game === this.game.value
      );
      console.log(data);
      if (data.length > 0) {
        console.log(data[0]);
        this.slotBookForm.get('slots').setValue(data[0].slot);
      }
    } else {
      const slots = {};
      if (!!slot) {
        Object.keys(slot.slot).forEach((key) => (slots[key] = []));
        this.slotBookForm.get('slots').setValue(slots);
      }
    }
  }

  validateSlotForm() {
    if (!!!this.profile) {
      this.dialog.open(LoginSignupComponent, {
        width: '60%',
        panelClass: 'custom-model',
      });
      return;
    } else if (this.slotBookForm.invalid) {
      this._toast.openSnackBar('Please A Select A Slot To Move Forward');
    } else {
      this.step.next();
    }
  }

  async validatePay() {
    const checkBooking = await this.checkBookedSlots();
    console.log(checkBooking);
    if (checkBooking.isBooked) {
      return;
    }
    const dialog = this.dialog.open(SharedPaymentComponent, {
      width: '40%',
      panelClass: 'custom-modal',
      disableClose: true,
      data: {
        totalAmount: this.billPrice.value,
        userId: this.bookingUserForm.get('userId').value,
      },
    });
    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.newBookConfirm();
      } else {
        console.log('Cancelled');
      }
    });
  }

  paymentStatus() {
    this.dialog.open(PaymentStatusComponent, {
      width: '35%',
      backdropClass: 'pay-popup',
      panelClass: 'custom-model',
    });
  }
  wavierForm() {
    const dialog = this.dialog.open(WaiverFormComponent, {
      width: '65%',
      height: '80%',
      panelClass: 'custom-model',
      data: {
        totalAmount: this.totalAmount.value,
        userId: this.bookingUserForm.get('userId').value,
      },
    });
  }

  checkSlots() {
    console.log(this.slots);
    console.log(this.allSlotsList);
    console.log(this.selectedSlots);
    console.log(this.dataSource);
  }

  async checkBookedSlots(): Promise<{ isBooked: boolean; slots: any }> {
    const slots = _.groupBy(this.dataSource.data, 'month');
    let isBooked = false;
    const selectedSlots = {};
    const months = Object.keys(slots);
    for (let index = 0; index < months.length; index++) {
      const key = months[index];
      const monthData = await this._eventsService
        .getSlotsForMonth(key)
        .pipe(take(1))
        .toPromise();
      slots[key].forEach((selectedSlot) => {
        const data = monthData.transaction.filter(
          (transaction) =>
            isEqual(transaction.date.toDate(), selectedSlot.date) &&
            selectedSlot.game === transaction.sportId
        );
        if (data.length > 0) {
          Object.keys(selectedSlot.slot).forEach((slot) => {
            if (isBooked) {
              return;
            }
            const transactions = data.filter(
              (tran) =>
                tran.sportId === selectedSlot.game &&
                slot === tran.subCategoryId
            )[0];
            console.log(slot);
            selectedSlot.slot[slot].forEach((time) => {
              console.log(time);
              if (transactions.slot[time].space === 0) {
                this._toast.openSnackBar(`
                  We are so sorry! The following slots have been taken moments ago.
                  ${transactions.date.toDate()}(${this.datePipe.transform(
                  this.getStart(time),
                  'h:mm a'
                )} : ${this.datePipe.transform(
                  this.getEnd(time),
                  'h:mm a'
                )} ). Please review  and update your cart to proceed. Thank you! We are so sorry! The following slots have been taken moments ago.  Date,
                 slot info, Date, slot info. Please review  and update your cart to proceed. Thank you!
                `);
                isBooked = true;
                return;
              } else {
                transactions.slot[time].space = 0;
              }
              console.log(data);
              console.log(isBooked);
            });
            if (isBooked) {
              return;
            }
          });
          if (isBooked) {
            return;
          }
          if (selectedSlots[selectedSlot.date]) {
            selectedSlots[selectedSlot.date] =
              selectedSlots[selectedSlot.date].concat(data);
          } else {
            selectedSlots[selectedSlot.date] = data;
          }
        } else {
          const masters = monthData.master.filter(
            (master) =>
              master.day === globalDays[getDay(selectedSlot.date).toString()] &&
              selectedSlot.game === master.sportId
          );
          console.log(masters);
          Object.keys(selectedSlot.slot).forEach((slot) => {
            if (isBooked) {
              return;
            }
            const transactions = masters.filter(
              (tran) =>
                tran.sportId === selectedSlot.game &&
                slot === tran.subCategoryId
            )[0];
            console.log(slot);
            selectedSlot.slot[slot].forEach((time) => {
              console.log(time);
              transactions.slot[time].space = 0;
            });
          });
          if (selectedSlots[selectedSlot.date]) {
            selectedSlots[selectedSlot.date] =
              selectedSlots[selectedSlot.date].concat(masters);
          } else {
            selectedSlots[selectedSlot.date] = masters;
          }
          console.log(masters);
        }
      });
      console.log(selectedSlots);
      console.log(monthData);
      console.log(isBooked);
    }
    // const
    // Object.keys(slots).forEach(async (key) => {
    //   const monthData = await this._eventsService.getSlotsForMonth(key).pipe(take(1)).toPromise();
    //   slots[key].forEach((selectedSlot) => {
    //     const data = monthData.transaction.filter((transaction) => isEqual(transaction.date.toDate(), selectedSlot.date)
    //       && selectedSlot.game === transaction.sportId);
    //     if (data.length > 0) {
    //       Object.keys(selectedSlot.slot).forEach((slot) => {
    //         if (isBooked) {
    //           return;
    //         }
    //         const transactions = data.filter((tran) => tran.sportId === selectedSlot.game && slot === tran.subCategoryId)[0];
    //         console.log(slot);
    //         selectedSlot.slot[slot].forEach((time) => {
    //           console.log(time);
    //           if (transactions.slot[time].space === 0) {
    //             this._toast.openSnackBar('Slot Already Booked Please Try Again');
    //             isBooked = true;
    //             return;
    //           } else {
    //             transactions.slot[time].space = 0;
    //           }
    //           console.log(data)
    //           console.log(isBooked);
    //         });
    //         if (isBooked) {
    //           return;
    //         }
    //       });
    //       if (isBooked) {
    //         return;
    //       }
    //       if (selectedSlots[selectedSlot.date]) {
    //         selectedSlots[selectedSlot.date] = selectedSlots[selectedSlot.date].concat(data);
    //       } else {
    //         selectedSlots[selectedSlot.date] = data;
    //       }
    //     } else {
    //       const masters = monthData.master.filter((master) => master.day === globalDays[getDay(selectedSlot.date).toString()]
    //         && selectedSlot.game === master.sportId);
    //       console.log(masters);
    //       Object.keys(selectedSlot.slot).forEach((slot) => {
    //         if (isBooked) {
    //           return;
    //         }
    //         const transactions = masters.filter((tran) => tran.sportId === selectedSlot.game && slot === tran.subCategoryId)[0];
    //         console.log(slot);
    //         selectedSlot.slot[slot].forEach((time) => {
    //           console.log(time);
    //           transactions.slot[time].space = 0;
    //         });
    //       });
    //       if (selectedSlots[selectedSlot.date]) {
    //         selectedSlots[selectedSlot.date] = selectedSlots[selectedSlot.date].concat(masters);
    //       } else {
    //         selectedSlots[selectedSlot.date] = masters;
    //       }
    //       console.log(masters);
    //     }
    //   });
    //   console.log(selectedSlots);
    //   console.log(monthData);
    //   console.log(isBooked);
    // });
    return { isBooked, slots: selectedSlots };
  }

  checkEmpty() {
    let isAvailable = false;
    this.slotsData.forEach((slot) => {
      if (Object.keys(slot.slot).length > 0) {
        isAvailable = true;
      }
    });
    return isAvailable;
  }

  /* ------------------------- Confirming New Booking ------------------------- */
  async newBookConfirm(): Promise<void> {
    this.submitted = true;
    if (this.slotBookForm.valid && this.slotTimingsForm.valid) {
      const checkBooking = await this.checkBookedSlots();
      if (checkBooking.isBooked) {
        return;
      }
      this.slots = checkBooking.slots;
      this.slotTimingsForm.get('createdTime').setValue(new Date());
      const bookingFormVal = {
        ...this.slotBookForm.value,
        ...this.slotTimingsForm.value,
        ...this.bookingUserForm.value,
      };
      console.log(this.slots);
      console.log(this.allSlotsList);
      const slot = [];
      Object.keys(this.slots).forEach((date) => {
        this.slots[date].forEach((item) => {
          const data = _.cloneDeep(item);
          console.log(data);

          if (!data.date) {
            console.log('data');
            data.date = parse(date);
            data.new = true;
          }
          slot.push(data);
        });
      });
      console.log(this.slots);
      let coupon;
      if (this.couponCode.value && !this.invalidCoupon) {
        coupon = {
          couponId: this.coupon.id,
          email: this.email.value,
          couponCode: this.couponCode.value,
          date: new Date(),
          discounted: this.coupon.discounted,
          type: this.coupon.type,
        };
      } else {
        bookingFormVal.couponCode = null;
        coupon = null;
      }
      bookingFormVal.date = new Date();
      bookingFormVal.bookedDates = this.selectedDays;
      this.spinner.show();
      setTimeout(() => {
        this.spinner.hide();
      }, 10000);
      const response = await this._eventsService.getOrderId(
        bookingFormVal,
        slot,
        coupon
      );
      this.spinner.hide();
      if (response) {
        this.paymentStatus();
      } else {
        this._toast.openSnackBar(
          'Unable To Book.Please Contact Admin If The Payment Has Gone Through Or Try Again Later'
        );
      }
    }
  }
  getStart(start_end: string) {
    let startendArray = start_end.split('_');
    let start = startendArray[0];
    let h = start.substring(0, 2);
    let s = start.substring(2);
    return setMinutes(setHours(new Date(), parseInt(h)), parseInt(s));
  }
  getEnd(start_end: string) {
    let startendArray = start_end.split('_');
    let end = startendArray[1];
    let h = end.substring(0, 2);
    let s = end.substring(2);
    return setMinutes(setHours(new Date(), parseInt(h)), parseInt(s));
  }

  getTime(time: string, part: number): { hour: number; minutes: number } {
    const startendArray = time.split('_');
    const end = startendArray[part];
    const hour = parseInt(end.substring(0, 2));
    const minutes = parseInt(end.substring(2));
    return { hour, minutes };
  }

  get viewType() {
    return this.slotBookForm.get('viewType');
  }
  get game() {
    return this.slotBookForm.get('game');
  }
  get date() {
    return this.slotBookForm.get('date');
  }
  get bookedSlot() {
    return this.slotBookForm.get('bookedSlot');
  }
  get totalAmount() {
    return this.slotTimingsForm.get('totalAmount');
  }
  get billPrice() {
    return this.slotTimingsForm.get('billPrice');
  }
  get name() {
    return this.bookingUserForm.get('name');
  }
  get email() {
    return this.bookingUserForm.get('email');
  }
  get couponCode() {
    return this.slotTimingsForm.get('couponCode');
  }
  get phoneNumber() {
    return this.bookingUserForm.get('phoneNumber');
  }
  get slot() {
    return this.slotBookForm.get('slots');
  }
}
