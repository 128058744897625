import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignWavierComponent } from './shared/sign-wavier/sign-wavier.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('src/app/modules/dashboard/dashboard.module').then(
        m => m.DashboardModule
      )
  },
  {
    path: 'live-tv',
    loadChildren: () =>
      import('src/app/modules/live-tv/live-tv.module').then(m => m.LiveTvModule)
  },
  {
    path: 'verify-email',
    loadChildren: () =>
      import('src/app/modules/thanks-page/thanks-page.module').then(
        m => m.ThanksPageModule
      )
  }
  // {
  //   path: '**',
  //   redirectTo: ''
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
