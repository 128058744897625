import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  refresh() {
    location.reload();
  }
}
