import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { FileProperties } from '../../models/file';
import { ToastService } from '../../../core/service/toast/toast.service';
@Component({
  selector: 'uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.scss']
})
export class UploadfileComponent implements OnInit {
    @Input() fileInput: AbstractControl;
    @Input() fileProperties: FileProperties;
    @Output() fileEvent: EventEmitter<any> = new EventEmitter<string>();
    task: AngularFireUploadTask;
    percentage: Observable<number>;
    snapshot: Observable<any>;
    downloadURL: any;
    fileSize: true;
    isHovering: boolean;
    file: File;
    constructor( private storage: AngularFireStorage, private toast: ToastService) { }
    ngOnInit() {

    }
    startUpload(file: File ) {
      // The storage path
      console.log(file);
      const path = `test/${Date.now()}_${file.name}`;
      // Reference to storage bucket
      const ref = this.storage.ref(path);
      // The main task
      this.task = this.storage.upload(path, file);
      // Progress monitoring
      this.percentage = this.task.percentageChanges();
      this.snapshot = this.task.snapshotChanges().
      pipe(finalize(async () => {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.fileInput.setValue(this.downloadURL);
      }));
    }
    removeFile() {
      this.downloadURL = "";
      this.fileInput.setValue(this.downloadURL);
    }

  /* ---------------------------- Custom Functions ---------------------------- */
    toggleHover(event: boolean) {
      this.isHovering = event;
    }
    onDrop(files: FileList) {
      this.file = files.item(0);
      console.log(this.file);
      if (this.fileProperties.acceptedFileTypes.includes(this.file.type) &&
      (this.file.size <= this.fileProperties.maxSize * 1024 * 1024)) {
          this.startUpload(this.file);
      } else {
        this.toast.openSnackBar("Image Size Is More");
      }
    }
}
