import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UploadFilesModule } from './upload-files/upload-files.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ExtensionPipe } from './pipes/extension.pipe';

import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { LoginSignupComponent } from './login-signup/login-signup.component';
import {
  MatTabsModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatIconModule,
  MatButtonModule,
  MatDialogModule,
  MatCheckboxModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatDatepickerModule,
  MatRadioModule,
  MatButtonToggleModule, MatChipsModule
} from '@angular/material';
import { UploadfileComponent } from './upload-files/uploadfile/uploadfile.component';
import { SharedPaymentComponent } from './payment/payment.component';
import { SignWavierComponent } from './sign-wavier/sign-wavier.component';
import { CheckInComponent } from './check-in/check-in.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    ExtensionPipe,
    LoginSignupComponent,
    UploadfileComponent,
    SharedPaymentComponent,
    SignWavierComponent,
    SafeHtmlPipe,
    CheckInComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    SwiperModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatRadioModule,
    MatButtonToggleModule,
    NgxSpinnerModule,
    MatChipsModule,
  ],
  exports: [
    UploadfileComponent,
    FormsModule,
    ReactiveFormsModule,
    SignWavierComponent,
    FlexLayoutModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    LoginSignupComponent,
    ExtensionPipe,
    SwiperModule,
    SharedPaymentComponent,
    SafeHtmlPipe,
    CheckInComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    ExtensionPipe
  ],
  entryComponents: [
    LoginSignupComponent,
    SharedPaymentComponent,
    SignWavierComponent,
    CheckInComponent
  ]
})
export class SharedModule { }
