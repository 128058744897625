import { ToastService } from './../../../../core/service/toast/toast.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { EventsService } from './../../services/events.service';
import { Component, OnInit, Inject } from '@angular/core';
import * as squareConnect from 'square-connect';
import {} from 'square-connect';
declare var SqPaymentForm : any; //magic to allow us to access the SquarePaymentForm lib
var nonces;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  nounce;
  constructor(
  @Inject(MAT_DIALOG_DATA)  public data: any,
  private service: EventsService , private dialogRef: MatDialogRef<PaymentComponent>,
  private toast: ToastService
    ) { }
  paymentForm; //this is our payment form object
  loading = true;

  ngOnInit() {
   const accessToken = 'EAAAEDrCrA0Un_aWNKzSfW3QTCZEDP3PFvjkl9tHQu4_NAeIyPxTMC99Y6E5kKvR';
    // Configure OAuth2 access token for authorization: oauth2
   const defaultClient = squareConnect.ApiClient.instance;
   const oauth2 = defaultClient.authentications['oauth2'];
   console.log(oauth2);
   oauth2.accessToken = accessToken;
   defaultClient.basePath = 'https://connect.squareup.com';

   const current = this;
   this.paymentForm = new SqPaymentForm({
        // Initialize the payment form elements
        // TODO: Replace with your sandbox application ID
        applicationId: "sq0idp-Yrw946umAlvInRoEL25nGg",
        inputClass: "sq-input",
        autoBuild: true,
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [
          {
            fontSize: "16px",
            lineHeight: "24px",
            padding: "16px",
            placeholderColor: "#a0a0a0",
            backgroundColor: "transparent"
          }
        ],
        // Initialize the credit card placeholders
        cardNumber: {
          elementId: "sq-card-number",
          placeholder: "Card Number"
        },
        cvv: {
          elementId: "sq-cvv",
          placeholder: "CVV"
        },
        expirationDate: {
          elementId: "sq-expiration-date",
          placeholder: "MM/YY"
        },
        postalCode: {
          elementId: "sq-postal-code",
          placeholder: "Postal"
        },
        // SqPaymentForm callback functions
        callbacks: {
          /*
           * callback function: cardNonceResponseReceived
           * Triggered when: SqPaymentForm completes a card nonce request
           */
          cardNonceResponseReceived: function(errors, nonce, cardData) {
            if (errors) {
              console.log(errors);
              console.log(nonce);
              console.log(cardData);
              // Log errors from nonce generation to the browser developer console.
              console.error("Encountered errors:");
              errors.forEach(function(error) {
                console.error("  " + error.message);
              });
              current.toast.openSnackBar('Enter Valid Fields');
              return;
            }
            nonces = nonce;

            console.log(nonces);
            alert(`The generated nonce is:\n${nonce}`);
            current.payment();
            // Uncomment the following block to
            // 1. assign the nonce to a form field and
            // 2. post the form to the payment processing handler

            // document.getElementById("card-nonce").value = nonce;
            // (<HTMLButtonElement>document.getElementById('nonce-form')).click();
            //TODO: Replace alert with code in step 2.1
          },
          paymentFormLoaded: function() {
            current.loading = false;
            console.log('loaded');
            /* HANDLE AS DESIRED */
          }
        }
  });
   this.paymentForm.build();
  }

  async payment() {
   console.log(nonces);
   const id = await this.service.paymentGateway(this.data);
   await this.service.bookSlot(nonces,id);
   this.dialogRef.close(true);
  }
  async cancel() {
  this.dialogRef.close();
  }

  async accessPayment(){
    const idempotency_key = 'sdfg';
    console.log(idempotency_key);

    // Charge the customer's card
    const payments_api = new squareConnect.PaymentsApi();
    const request_body = {
      source_id:nonces,
      amount_money: {
        amount: 100, // $1.00 charge
        currency: 'USD'
      },
      idempotency_key: idempotency_key
    };

    try {
      const response = await payments_api.createPayment(request_body);
      console.log(response);
      // res.status(200).json({
      //   'title': 'Payment Successful',
      //   'result': response
      // });
    } catch (error) {
      console.log(error);
      // res.status(500).json({
      //   'title': 'Payment Failure',
      //   'result': error.response.text
      // });
    }
  }
  onGetCardNonce(event){
    console.log(event);
      // Don't submit the form until SqPaymentForm returns with a nonce
      event.preventDefault();
      // Request a nonce from the SqPaymentForm object
      this.paymentForm.requestCardNonce();
  }
}
