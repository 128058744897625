import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/modules/dashboard/services/users.service';
import { Observable } from 'rxjs';
import { Events } from 'src/app/modules/sports-listing/model/event';
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  constructor(private _usersService: UsersService) {}
  whatWeDo: any;
  faqs: Array<any> = [];
  customCollapsedHeight = '40px';
  customExpandedHeight = '40px';
  ngOnInit() {
    const sub2 = this._usersService
      .getCommonThings('aboutUs')
      .subscribe(data => {
        this.whatWeDo = !!data ? data.text : data;
      });

    this._usersService.getFaqs().subscribe(data => {
      this.faqs = data;
    });
  }
}
