import { User } from './../../../dashboard/models/user';
import { ToastService } from './../../../../core/service/toast/toast.service';
import { AuthService } from 'src/app/core/auth.service';
import { SportService } from './../../../sports-listing/service/sport.service';
import { EventsService } from './../../services/events.service';
import { BookEventComponent } from './../../components/book-event/book-event.component';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UsersService } from 'src/app/modules/dashboard/services/users.service';
import { Subscription } from 'rxjs';
import { HomePageSlider } from 'src/app/modules/dashboard/models/user';
import { Events } from 'src/app/modules/sports-listing/model/event';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { LoginSignupComponent } from 'src/app/shared/login-signup/login-signup.component';
import { CheckInComponent } from 'src/app/shared/check-in/check-in.component';
export interface SlideArray {
  image: string;
}

export interface UpdateArray {
  image: string;
  title: string;
  subTitle: string;
  action: string;
}
export interface EventArray {
  image: string;
  title: string;
  subTitle: string;
  action: string;
}
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
  public show: boolean = true;
  @ViewChild('scroll', { static: false }) public scrollBottom: ElementRef;
  contactUsForm: FormGroup;
  submitted = false;
  commonThings;
  minDate = new Date();
  subscription: Subscription;
  bannerImages: Array<string>;
  slidersArray = [
    '../../assets/images/banners/1.jpg',
    '../../assets/images/banners/22.jpg'
  ];
  sportImage;
  eventImage;
  galleryImages = [];
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    effect: 'fade',
    loop: false,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    // autoplay: false,
    autoplay: {
      delay: 6000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };
  public eventConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    // effect: 'fade',
    loop: false,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    //lazy: true,
    // autoplay: false,
    autoplay: {
      delay: 6000,
      disableOnInteraction: true
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.event-swiper-button-next',
      prevEl: '.event-swiper-button-prev'
    }
  };
  // slidersArray: HomePageSlider[];
  load = false;
  public slides = ['First slide', 'Second slide'];
  events: Array<Events> = [];
  updates: Array<string> = [];
  public disabled: boolean = false;
  subscriptions: Array<Subscription> = [];
  sportsList: Array<any> = [];
  isLoggedIn: User;
  whatWeDo: any;
  constructor(
    private _dialog: MatDialog,
    private _fb: FormBuilder,
    private _usersService: UsersService,
    private service: EventsService,
    private sportService: SportService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService
  ) { }

  bookEvent() {
    this._dialog.open(BookEventComponent, {
      width: '60%',
      panelClass: 'custom-modal'
    });
  }
  scrollToBottom(): void {
    try {
      window.scrollTo(0, document.body.scrollHeight);
    } catch (err) {
      console.log(err);
    }
  }
  ngOnInit() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      console.log('mobile detected');
      // some code..
    }
    this.spinner.show();



    // this.service.getUserDetailss(userDeatail);
    setTimeout(() => {
      console.log('forced');
      this.spinner.hide();
    }, 4000);
    const sub1 = this.service.getHomeDetail().subscribe(data => {
      console.log(data);
      this.bannerImages = data.bannerImages;
      this.sportImage = data.sportImage;
      this.eventImage = data.eventImage;
      this.galleryImages = data.homeGalley;
      this.updates = data.updates;
      this.config.lazy = {
        loadPrevNext: true
      };
      this.load = true;
      this.spinner.hide();
    });

    this.contactUsForm = this._fb.group({
      name: ['', [Validators.required, this.noWhitespace]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, this.noWhitespace]],
      subject: ['', [Validators.required, this.noWhitespace]],
      createdTime: [new Date()]
    });
    const sub2 = this._usersService
      .getCommonThings('whatWeDo')
      .subscribe(data => {
        this.whatWeDo = !!data ? data.text : data;
      });

    const sub3 = this.sportService.getHomeEvents().subscribe(data => {
      console.log(data);
      this.events = data.filter((event) => !event.isPrivate);
      this.eventConfig.lazy = {
        loadPrevNext: true
      };
    });

    const sub4 = this.service.getAllStaticSports().subscribe(data => {
      this.sportsList = _.orderBy(data, 'position');
    });

    this.auth.profile.subscribe((data) => {
      if (data) {
        this.isLoggedIn = data;
      } else {
        this.isLoggedIn = null;
      }
    })
    this.subscriptions = [sub1, sub2, sub3, sub4];

  }

  login() {
    if (!this.isLoggedIn) {
      this._dialog.open(LoginSignupComponent, {
        width: '60%',
        panelClass: 'custom-model'
      });
    }
  }

  /* ---------------------- Navigate To My Accouont Page ---------------------- */
  myAccountPage() {
    if (!this.isLoggedIn) {
      this.login();
      return;
    }
    this.router.navigate(['/profile']);
  }



  ngOnDestroy() {
    this.subscriptions.forEach(subs => {
      if (subs) {
        subs.unsubscribe();
      }
    });
  }

  initSwiper(as) { 
    // console.log(as);
  }

  navigate() {
    window.open(
      'https://www.google.com/maps?ll=42.559786,-83.108807&z=13&t=m&hl=en-US&gl=US&mapclient=embed&cid=576135955273182064',
      'blank'
    );
  }

  bookNow(){
    window.open("https://www.fresha.com/a/cricindo-arena-troy-mi-troy-2691-john-r-road-aum4unf1/all-offer?menu=true", "_blank");
  }

  checkIn() {
    // if (!this.isLoggedIn) {
    //   this.login();
    //   this.toast.openSnackBar('Please Login Before CheckIn');
    //   return;
    // }

    // if (!this.isLoggedIn.isWavierSigned) {
    //   this.toast.openSnackBar('Please Sign Wavier Before CheckIn');
    //   this.router.navigate(['/sign-wavier']);
    //   return;
    // }

    window.open("https://www.fresha.com/appointments/632174369", "_blank");

    // this._dialog.open(CheckInComponent, {
    //   width: '60%',
    //   panelClass: 'custom-model'
    // });
  }
  public noWhitespace(control: FormControl) {
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  async contactSubmit(): Promise<void> {
    this.submitted = true;

    if (this.contactUsForm.valid) {
      this._usersService
        .newGeneralEnquiry(this.contactUsForm.value)
        .then(data => {
          this.contactUsForm.reset();
        });
    }
    return;
  }

  get name() {
    return this.contactUsForm.get('name');
  }
  get subject() {
    return this.contactUsForm.get('subject');
  }
  get email() {
    return this.contactUsForm.get('email');
  }
  get phoneNumber() {
    return this.contactUsForm.get('phoneNumber');
  }

}
