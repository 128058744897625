import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DomSanitizer } from '@angular/platform-browser';
export interface SlideArray {
  image: string;
}
@Component({
  selector: 'app-live-page',
  templateUrl: './live-page.component.html',
  styleUrls: ['./live-page.component.scss']
})
export class LivePageComponent implements OnInit {

  bannerImages: SlideArray[] = [
    { image: '../../../../../assets/images/banners/add_1.png' },
    { image: '../../../../../assets/images/banners/add_2.png' },
    { image: '../../../../../assets/images/banners/add_3.png' },
    { image: '../../../../../assets/images/banners/add_4.png' },
    { image: '../../../../../assets/images/banners/add_5.png' },
    { image: '../../../../../assets/images/banners/add_6.png' },
    { image: '../../../../../assets/images/banners/add_7.png' },
    { image: '../../../../../assets/images/banners/add_8.png' },
  ];
  public slides = [
    'First slide',
    'Second slide',
  ];
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 4,
    zoom: true,
    keyboard: true,
    loop: false,
    spaceBetween: 30,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
     autoplay: {
       delay: 5500,
       disableOnInteraction: false,
     },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
       prevEl: '.swiper-button-prev',
     },
     breakpoints:{
      640:{
           slidesPerView: 1,
          }
   }
  };

  @ViewChild('fram',{static:false}) public frame: ElementRef;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  this.refreshLink(); 
  }

  refreshLink(){
    setTimeout(() => {
      console.log(this.frame) ;
      this.frame.nativeElement.src = this.getsafeUrl();
      this.refreshLink();
      }, 30000);
  }


  getsafeUrl(){
    console.log('called');
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://docs.google.com/spreadsheets/d/e/2PACX-1vQsQytfEu4sjiDAsAVRLFeTyQN_SVo3_MIpif2J8xuUbPI7dTFozVzFPD3fNV0Efn6J34atEpwJcZnN/pubhtml?gid=2054126247&amp;single=true&amp;widget=true&amp;headers=false;range=B5:D9");
  }

  initSwiper(as){
    console.log(as);
    }
}
